@charset "UTF-8";
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700';

:root {
    --blue: #146d99;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #e12d2d;
    --orange: #ff6704;
    --yellow: #ffc107;
    --green: #289f77;
    --teal: #19a9c3;
    --cyan: #155169;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #ff6704;
    --secondary: #5c5c5e;
    --success: #344251;
    --info: #8a9bad;
    --warning: #e12d2d;
    --danger: #e12d2d;
    --light: #56697e;
    --dark: #354251;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #56697e;
    text-align: left;
    background-color: #fff;
}

[tabindex='-1']:focus:not(:focus-visible) {
    outline: 0 !important;
}

hr {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #ff6704;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #b74800;
    text-decoration: underline;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #8a9bad;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: none;
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

select {
    word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: 2.5rem;
}

h2,
.h2 {
    font-size: 2rem;
}

h3,
.h3 {
    font-size: 1.75rem;
}

h4,
.h4 {
    font-size: 1.5rem;
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer::before {
    content: '\2014\00A0';
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 90%;
    color: #6c757d;
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word;
}

a > code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px;
    }
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}

.row-cols-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.row-cols-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.row-cols-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.row-cols-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.row-cols-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
}

.row-cols-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

.col-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

.col-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -moz-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -moz-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.order-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -moz-box-ordinal-group: 14;
    -ms-flex-order: 13;
    order: 13;
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -moz-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -moz-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -moz-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -moz-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -moz-box-ordinal-group: 7;
    -ms-flex-order: 6;
    order: 6;
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -moz-box-ordinal-group: 8;
    -ms-flex-order: 7;
    order: 7;
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -moz-box-ordinal-group: 9;
    -ms-flex-order: 8;
    order: 8;
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -moz-box-ordinal-group: 10;
    -ms-flex-order: 9;
    order: 9;
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -moz-box-ordinal-group: 11;
    -ms-flex-order: 10;
    order: 10;
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -moz-box-ordinal-group: 12;
    -ms-flex-order: 11;
    order: 11;
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -moz-box-ordinal-group: 13;
    -ms-flex-order: 12;
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -moz-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-sm-1 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-sm-2 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-sm-3 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-sm-4 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-sm-5 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-sm-6 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -moz-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-md-1 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-md-2 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-md-3 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-md-4 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-md-5 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-md-6 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-md-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.33333%;
    }

    .offset-md-2 {
        margin-left: 16.66667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.33333%;
    }

    .offset-md-5 {
        margin-left: 41.66667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.33333%;
    }

    .offset-md-8 {
        margin-left: 66.66667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.33333%;
    }

    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -moz-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-lg-1 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-lg-2 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-lg-3 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-lg-4 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-lg-5 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-lg-6 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -moz-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xl-1 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xl-2 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xl-3 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .row-cols-xl-4 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xl-5 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 20%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xl-6 > * {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -moz-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -moz-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -moz-box-ordinal-group: 14;
        -ms-flex-order: 13;
        order: 13;
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -moz-box-ordinal-group: 1;
        -ms-flex-order: 0;
        order: 0;
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -moz-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -moz-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -moz-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -moz-box-ordinal-group: 5;
        -ms-flex-order: 4;
        order: 4;
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -moz-box-ordinal-group: 6;
        -ms-flex-order: 5;
        order: 5;
    }

    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -moz-box-ordinal-group: 7;
        -ms-flex-order: 6;
        order: 6;
    }

    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -moz-box-ordinal-group: 8;
        -ms-flex-order: 7;
        order: 7;
    }

    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -moz-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }

    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -moz-box-ordinal-group: 10;
        -ms-flex-order: 9;
        order: 9;
    }

    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -moz-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
    }

    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -moz-box-ordinal-group: 12;
        -ms-flex-order: 11;
        order: 11;
    }

    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -moz-box-ordinal-group: 13;
        -ms-flex-order: 12;
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #56697e;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
    border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #56697e;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: #ffd4b9;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
    border-color: #ffb07c;
}

.table-hover .table-primary:hover {
    background-color: #ffc4a0;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #ffc4a0;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
    background-color: #d1d1d2;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
    border-color: #aaaaab;
}

.table-hover .table-secondary:hover {
    background-color: #c4c4c5;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: #c4c4c5;
}

.table-success,
.table-success > th,
.table-success > td {
    background-color: #c6cace;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
    border-color: #959da5;
}

.table-hover .table-success:hover {
    background-color: #b8bdc2;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #b8bdc2;
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: #dee3e8;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
    border-color: #c2cbd4;
}

.table-hover .table-info:hover {
    background-color: #cfd6de;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #cfd6de;
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #f7c4c4;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
    border-color: #ef9292;
}

.table-hover .table-warning:hover {
    background-color: #f4aeae;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #f4aeae;
}

.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: #f7c4c4;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
    border-color: #ef9292;
}

.table-hover .table-danger:hover {
    background-color: #f4aeae;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #f4aeae;
}

.table-light,
.table-light > th,
.table-light > td {
    background-color: #d0d5db;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
    border-color: #a7b1bc;
}

.table-hover .table-light:hover {
    background-color: #c2c8d0;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #c2c8d0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: #c6cace;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #969da5;
}

.table-hover .table-dark:hover {
    background-color: #b8bdc2;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #b8bdc2;
}

.table-active,
.table-active > th,
.table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md > .table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #56697e;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        -webkit-transition: none;
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #56697e;
}

.form-control:focus {
    color: #56697e;
    background-color: #fff;
    border-color: #ffb484;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.form-control::-webkit-input-placeholder {
    color: #8a9bad;
    opacity: 1;
}

.form-control::-moz-placeholder {
    color: #8a9bad;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #8a9bad;
    opacity: 1;
}

.form-control::placeholder {
    color: #8a9bad;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

select.form-control:focus::-ms-value {
    color: #56697e;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

.col-form-label {
    padding-top: -webkit-calc(0.375rem + 1px);
    padding-top: calc(0.375rem + 1px);
    padding-bottom: -webkit-calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    font-weight: bold;
    line-height: 1.5;
    color: #56697e;
}

.col-form-label-lg {
    padding-top: -webkit-calc(0.5rem + 1px);
    padding-top: calc(0.5rem + 1px);
    padding-bottom: -webkit-calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: -webkit-calc(0.25rem + 1px);
    padding-top: calc(0.25rem + 1px);
    padding-bottom: -webkit-calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #56697e;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: -webkit-calc(1.5em + 0.5rem + 2px);
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.form-control-lg {
    height: -webkit-calc(1.5em + 1rem + 2px);
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
    height: auto;
}

textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
    color: #8a9bad;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #344251;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(52, 66, 81, 0.9);
    border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
    display: block;
}

.form-control .was-validated :valid,
.form-control .is-valid {
    border-color: #344251;
    padding-right: -webkit-calc(1.5em + 0.75rem);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23344251' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right -webkit-calc(0.375em + 0.1875rem) center;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: -webkit-calc(0.75em + 0.375rem) -webkit-calc(0.75em + 0.375rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control .was-validated :valid:focus,
.form-control .is-valid:focus {
    border-color: #344251;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.25);
}

textarea.form-control .was-validated :valid,
textarea.form-control .is-valid {
    padding-right: -webkit-calc(1.5em + 0.75rem);
    padding-right: calc(1.5em + 0.75rem);
    background-position: top -webkit-calc(0.375em + 0.1875rem) right -webkit-calc(0.375em + 0.1875rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select .was-validated :valid,
.custom-select .is-valid {
    border-color: #344251;
    padding-right: -webkit-calc(0.75em + 2.3125rem);
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23344251' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
            #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) -webkit-calc(0.75em + 0.375rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23344251' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
            #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select .was-validated :valid:focus,
.custom-select .is-valid:focus {
    border-color: #344251;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.25);
}

.form-check-input .was-validated :valid ~ .form-check-label,
.form-check-input .is-valid ~ .form-check-label {
    color: #344251;
}

.form-check-input .was-validated :valid ~ .valid-feedback,
.form-check-input .was-validated :valid ~ .valid-tooltip,
.form-check-input .is-valid ~ .valid-feedback,
.form-check-input .is-valid ~ .valid-tooltip {
    display: block;
}

.custom-control-input .was-validated :valid ~ .custom-control-label,
.custom-control-input .is-valid ~ .custom-control-label {
    color: #344251;
}

.custom-control-input .was-validated :valid ~ .custom-control-label::before,
.custom-control-input .is-valid ~ .custom-control-label::before {
    border-color: #344251;
}

.custom-control-input .was-validated :valid:checked ~ .custom-control-label::before,
.custom-control-input .is-valid:checked ~ .custom-control-label::before {
    border-color: #485b70;
    background-color: #485b70;
}

.custom-control-input .was-validated :valid:focus ~ .custom-control-label::before,
.custom-control-input .is-valid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.25);
}

.custom-control-input .was-validated :valid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input .is-valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #344251;
}

.custom-file-input .was-validated :valid ~ .custom-file-label,
.custom-file-input .is-valid ~ .custom-file-label {
    border-color: #344251;
}

.custom-file-input .was-validated :valid:focus ~ .custom-file-label,
.custom-file-input .is-valid:focus ~ .custom-file-label {
    border-color: #344251;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e12d2d;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(225, 45, 45, 0.9);
    border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
    display: block;
}

.form-control .was-validated :invalid,
.form-control .is-invalid {
    border-color: #e12d2d;
    padding-right: -webkit-calc(1.5em + 0.75rem);
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E12D2D' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E12D2D' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right -webkit-calc(0.375em + 0.1875rem) center;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: -webkit-calc(0.75em + 0.375rem) -webkit-calc(0.75em + 0.375rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.form-control .was-validated :invalid:focus,
.form-control .is-invalid:focus {
    border-color: #e12d2d;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.25);
}

textarea.form-control .was-validated :invalid,
textarea.form-control .is-invalid {
    padding-right: -webkit-calc(1.5em + 0.75rem);
    padding-right: calc(1.5em + 0.75rem);
    background-position: top -webkit-calc(0.375em + 0.1875rem) right -webkit-calc(0.375em + 0.1875rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.custom-select .was-validated :invalid,
.custom-select .is-invalid {
    border-color: #e12d2d;
    padding-right: -webkit-calc(0.75em + 2.3125rem);
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E12D2D' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E12D2D' stroke='none'/%3e%3c/svg%3e")
            #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) -webkit-calc(0.75em + 0.375rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.75rem center/8px 10px,
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23E12D2D' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E12D2D' stroke='none'/%3e%3c/svg%3e")
            #fff no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.custom-select .was-validated :invalid:focus,
.custom-select .is-invalid:focus {
    border-color: #e12d2d;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.25);
}

.form-check-input .was-validated :invalid ~ .form-check-label,
.form-check-input .is-invalid ~ .form-check-label {
    color: #e12d2d;
}

.form-check-input .was-validated :invalid ~ .invalid-feedback,
.form-check-input .was-validated :invalid ~ .invalid-tooltip,
.form-check-input .is-invalid ~ .invalid-feedback,
.form-check-input .is-invalid ~ .invalid-tooltip {
    display: block;
}

.custom-control-input .was-validated :invalid ~ .custom-control-label,
.custom-control-input .is-invalid ~ .custom-control-label {
    color: #e12d2d;
}

.custom-control-input .was-validated :invalid ~ .custom-control-label::before,
.custom-control-input .is-invalid ~ .custom-control-label::before {
    border-color: #e12d2d;
}

.custom-control-input .was-validated :invalid:checked ~ .custom-control-label::before,
.custom-control-input .is-invalid:checked ~ .custom-control-label::before {
    border-color: #e75a5a;
    background-color: #e75a5a;
}

.custom-control-input .was-validated :invalid:focus ~ .custom-control-label::before,
.custom-control-input .is-invalid:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.25);
}

.custom-control-input .was-validated :invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input .is-invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #e12d2d;
}

.custom-file-input .was-validated :invalid ~ .custom-file-label,
.custom-file-input .is-invalid ~ .custom-file-label {
    border-color: #e12d2d;
}

.custom-file-input .was-validated :invalid:focus ~ .custom-file-label,
.custom-file-input .is-invalid:focus ~ .custom-file-label {
    border-color: #e12d2d;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.25);
}

.form-inline {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -moz-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }

    .form-inline .form-check {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #56697e;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        -webkit-transition: none;
        transition: none;
    }
}

.btn:hover {
    color: #56697e;
    text-decoration: none;
}

.btn-orange:hover {
    color: #fff;
}

.btn:focus,
.btn.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.btn.disabled,
.btn:disabled {
    opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #ff6704;
    border-color: #ff6704;
}

.btn-primary:hover {
    color: #fff;
    background-color: #dd5700;
    border-color: #d05200;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #dd5700;
    border-color: #d05200;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 126, 42, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 126, 42, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #ff6704;
    border-color: #ff6704;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #d05200;
    border-color: #c34d00;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 126, 42, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 126, 42, 0.5);
}

.btn-secondary {
    color: #fff;
    background-color: #5c5c5e;
    border-color: #5c5c5e;
}

.btn-secondary:hover {
    color: #fff;
    background-color: #49494b;
    border-color: #434344;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #fff;
    background-color: #49494b;
    border-color: #434344;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 116, 118, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(116, 116, 118, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #5c5c5e;
    border-color: #5c5c5e;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #434344;
    border-color: #3c3c3e;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(116, 116, 118, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(116, 116, 118, 0.5);
}

.btn-success {
    color: #fff;
    background-color: #344251;
    border-color: #344251;
}

.btn-success:hover {
    color: #fff;
    background-color: #252f3a;
    border-color: #202932;
}

.btn-success:focus,
.btn-success.focus {
    color: #fff;
    background-color: #252f3a;
    border-color: #202932;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 94, 107, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(82, 94, 107, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #344251;
    border-color: #344251;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #202932;
    border-color: #1b222a;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(82, 94, 107, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(82, 94, 107, 0.5);
}

.btn-info {
    color: #212529;
    background-color: #8a9bad;
    border-color: #8a9bad;
}

.btn-info:hover {
    color: #fff;
    background-color: #74889d;
    border-color: #6c8198;
}

.btn-info:focus,
.btn-info.focus {
    color: #fff;
    background-color: #74889d;
    border-color: #6c8198;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 137, 153, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(122, 137, 153, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #212529;
    background-color: #8a9bad;
    border-color: #8a9bad;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #6c8198;
    border-color: #667b91;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 137, 153, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(122, 137, 153, 0.5);
}

.btn-warning {
    color: #fff;
    background-color: #e12d2d;
    border-color: #e12d2d;
}

.btn-warning:hover {
    color: #fff;
    background-color: #cb1d1d;
    border-color: #c01b1b;
}

.btn-warning:focus,
.btn-warning.focus {
    color: #fff;
    background-color: #cb1d1d;
    border-color: #c01b1b;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 77, 77, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(230, 77, 77, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #fff;
    background-color: #e12d2d;
    border-color: #e12d2d;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #c01b1b;
    border-color: #b41a1a;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 77, 77, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(230, 77, 77, 0.5);
}

.btn-danger {
    color: #fff;
    background-color: #e12d2d;
    border-color: #e12d2d;
}

.btn-danger:hover {
    color: #fff;
    background-color: #cb1d1d;
    border-color: #c01b1b;
}

.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    background-color: #cb1d1d;
    border-color: #c01b1b;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 77, 77, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(230, 77, 77, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #e12d2d;
    border-color: #e12d2d;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c01b1b;
    border-color: #b41a1a;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(230, 77, 77, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(230, 77, 77, 0.5);
}

.btn-light {
    color: #fff;
    background-color: #56697e;
    border-color: #56697e;
}

.btn-light:hover {
    color: #fff;
    background-color: #465667;
    border-color: #415060;
}

.btn-light:focus,
.btn-light.focus {
    color: #fff;
    background-color: #465667;
    border-color: #415060;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 128, 145, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(111, 128, 145, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #fff;
    background-color: #56697e;
    border-color: #56697e;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
    color: #fff;
    background-color: #415060;
    border-color: #3c4958;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(111, 128, 145, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(111, 128, 145, 0.5);
}

.btn-dark {
    color: #fff;
    background-color: #354251;
    border-color: #354251;
}

.btn-dark:hover {
    color: #fff;
    background-color: #262f3a;
    border-color: #212932;
}

.btn-dark:focus,
.btn-dark.focus {
    color: #fff;
    background-color: #262f3a;
    border-color: #212932;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 94, 107, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(83, 94, 107, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #354251;
    border-color: #354251;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #212932;
    border-color: #1c232a;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(83, 94, 107, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(83, 94, 107, 0.5);
}

.btn-outline-primary {
    color: #ff6704;
    border-color: #ff6704;
}

.btn-outline-primary:hover {
    color: #ff6704;
    background-color: #fff;
    border-color: #ff6704;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #ff6704;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff6704;
    border-color: #ff6704;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.5);
}

.btn-outline-secondary {
    color: #5c5c5e;
    border-color: #5c5c5e;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #5c5c5e;
    border-color: #5c5c5e;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(92, 92, 94, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(92, 92, 94, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #5c5c5e;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5c5c5e;
    border-color: #5c5c5e;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(92, 92, 94, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(92, 92, 94, 0.5);
}

.btn-outline-success {
    color: #344251;
    border-color: #344251;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #344251;
    border-color: #344251;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #344251;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #344251;
    border-color: #344251;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.5);
}

.btn-outline-info {
    color: #8a9bad;
    border-color: #8a9bad;
}

.btn-outline-info:hover {
    color: #212529;
    background-color: #8a9bad;
    border-color: #8a9bad;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(138, 155, 173, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(138, 155, 173, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #8a9bad;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #8a9bad;
    border-color: #8a9bad;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(138, 155, 173, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(138, 155, 173, 0.5);
}

.btn-outline-warning {
    color: #e12d2d;
    border-color: #e12d2d;
}

.btn-outline-warning:hover {
    color: #fff;
    background-color: #e12d2d;
    border-color: #e12d2d;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #e12d2d;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #e12d2d;
    border-color: #e12d2d;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
}

.btn-outline-danger {
    color: #e12d2d;
    border-color: #e12d2d;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #e12d2d;
    border-color: #e12d2d;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #e12d2d;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e12d2d;
    border-color: #e12d2d;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
}

.btn-outline-light {
    color: #56697e;
    border-color: #56697e;
}

.btn-outline-light:hover {
    color: #fff;
    background-color: #56697e;
    border-color: #56697e;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(86, 105, 126, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(86, 105, 126, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #56697e;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #56697e;
    border-color: #56697e;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(86, 105, 126, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(86, 105, 126, 0.5);
}

.btn-outline-dark {
    color: #354251;
    border-color: #354251;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #354251;
    border-color: #354251;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(53, 66, 81, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(53, 66, 81, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #354251;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #354251;
    border-color: #354251;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(53, 66, 81, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(53, 66, 81, 0.5);
}

.btn-link {
    font-weight: 400;
    color: #56697e;
    text-decoration: underline;
}

.btn-link:hover {
    color: light(#56697e, 10%);
    text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: underline;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
    pointer-events: none;
}

.card-hide a.btn-link:not(.collapsed):before {
    content: '';
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block + .btn-block {
    margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
    width: 100%;
}

.btn-primary {
    width: 185px;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    background-color: #ff6704;
    color: #fff;
}

.btn-secondary {
    width: 185px;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    background-color: #5c5c5e;
    color: #fff;
}

.btn-warning {
    width: 185px;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    background-color: #fff;
    border-color: #e12d2d;
    color: #e12d2d;
}

.btn-disable {
    width: 185px;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    background-color: #b8c7d7;
    color: #fff;
}

.btn-upload {
    background-color: #fff;
    border-color: #ff6704;
    color: #ff6704;
}

.btn-upload:hover {
    color: #d05200;
    border-color: #d05200;
}

.btn-add {
    width: 185px;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    background-color: #fff;
    border-color: #56697e;
    color: #56697e;
}

.btn-add i {
    margin-right: 7px;
    position: relative;
    top: 1px;
}

.btn-bin {
    font-size: 1.5rem;
    border: solid 1px #fff;
    background: #fff;
    color: #e12d2d;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    padding-top: 3px;
}

.btn-bin:hover {
    border: solid 1px #e12d2d;
    color: #d61f1f;
}

.btn-bin:focus {
    outline: none;
}

.fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        -webkit-transition: none;
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition: height 0.35s ease;
    transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        -webkit-transition: none;
        transition: none;
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #56697e;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}

.dropdown-menu-left {
    right: auto;
    left: 0;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0;
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
}

.dropleft .dropdown-toggle::after {
    display: none;
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
    right: auto;
    bottom: auto;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #ff6704;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: #212529;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
    z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
    width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 0%;
    -moz-box-flex: 1;
    -ms-flex: 1 1 0%;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
    margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
    z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

app-calendar-input.rounded .form-group .input-group input {
    border-radius: 4px;
}

.input-group > .custom-file {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
    z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #56697e;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
    margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
    height: -webkit-calc(1.5em + 1rem + 2px);
    height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
    height: -webkit-calc(1.5em + 0.5rem + 2px);
    height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
    padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-inline {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #ff6704;
    background-color: #ff6704;
}

.custom-control-input:focus ~ .custom-control-label::before {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ffb484;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #ffd3b7;
    border-color: #ffd3b7;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: '';
    background-color: #fff;
    border: #adb5bd solid 1px;
}

.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #ff6704;
    background-color: #ff6704;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(255, 103, 4, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(255, 103, 4, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(255, 103, 4, 0.5);
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
    top: -webkit-calc(0.25rem + 2px);
    top: calc(0.25rem + 2px);
    left: -webkit-calc(-2.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: -webkit-calc(1rem - 4px);
    width: calc(1rem - 4px);
    height: -webkit-calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: -webkit-transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none;
    }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    -ms-transform: translateX(0.75rem);
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(255, 103, 4, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8a9bad;
    vertical-align: middle;
    background: #fff
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
        no-repeat right 0.75rem center/8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    appearance: none;
    -webkit-appearance: none;
}

.custom-select:focus {
    border-color: #ffb484;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.custom-select:focus::-ms-value {
    color: #56697e;
    background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
}

.custom-select::-ms-expand {
    display: none;
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #56697e;
}

.custom-select-sm {
    height: -webkit-calc(1.5em + 0.5rem + 2px);
    height: calc(1.5em + 0.5rem + 2px);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
}

.custom-select-lg {
    height: -webkit-calc(1.5em + 1rem + 2px);
    height: calc(1.5em + 1rem + 2px);
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
    border-color: #ffb484;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: -webkit-calc(1.5em + 0.75rem + 2px);
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #56697e;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: -webkit-calc(1.5em + 0.75rem);
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #56697e;
    content: 'Browse';
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #ff6704;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none;
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #ffd3b7;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ff6704;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none;
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #ffd3b7;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #ff6704;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none;
    }
}

.custom-range::-ms-thumb:active {
    background-color: #ffd3b7;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
    cursor: default;
}

.custom-range:disabled::-ms-thumb {
    background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        -webkit-transition: none;
        transition: none;
    }
}

.custom-control-input:checked ~ .custom-control-label::before {
    content: '\e90f';
    font-family: 'icon';
    font-size: 0.85rem;
    line-height: 1.1;
}

.nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link span.notification {
    font-size: 10px;
    color: white;
    background-color: #ff6704;
    border-radius: 7.5px;
    padding: 0.3em 0.5em;
    margin-left: 0.4em;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff;
    background-color: #ff6704;
}

.nav-fill .nav-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.navbar {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.navbar .btn {
    font-size: 2em;
}

.navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none;
}

.navbar-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
}

@media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-sm .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-sm .navbar-collapse {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-md .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-md .navbar-collapse {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-lg .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-lg .navbar-collapse {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -webkit-flex-flow: row nowrap;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .navbar-expand-xl .navbar-nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }

    .navbar-expand-xl .navbar-collapse {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-flex-basis: auto;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }

    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff;
}

.card {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.75rem;
    -webkit-box-shadow: 0px 2px 2px rgba(179, 179, 179, 0.5);
    box-shadow: 0px 2px 2px rgba(179, 179, 179, 0.5);
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.card-body {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.card-title {
    margin-bottom: 0.75rem;
}

.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
    border-radius: -webkit-calc(0.25rem - 1px) -webkit-calc(0.25rem - 1px) 0 0;
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}

.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
    border-radius: 0 0 -webkit-calc(0.25rem - 1px) -webkit-calc(0.25rem - 1px);
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: -webkit-calc(0.25rem - 1px);
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: -webkit-calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: -webkit-calc(0.25rem - 1px);
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: -webkit-calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-img {
    border-radius: 0.75rem;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-deck {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0%;
        -moz-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group > .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: row wrap;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }

    .card-group > .card {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 0%;
        -moz-box-flex: 1;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }

    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }

    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion > .card {
    overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px;
}

.breadcrumb {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #ff6704;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-link:hover {
    z-index: 2;
    color: #b74800;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.25);
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #ff6704;
    border-color: #ff6704;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        -webkit-transition: none;
        transition: none;
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

.badge-primary {
    color: #fff;
    background-color: #ff6704;
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #d05200;
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(255, 103, 4, 0.5);
}

.badge-secondary {
    color: #fff;
    background-color: #5c5c5e;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #434344;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(92, 92, 94, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(92, 92, 94, 0.5);
}

.badge-success {
    color: #fff;
    background-color: #344251;
}

a.badge-success:hover,
a.badge-success:focus {
    color: #fff;
    background-color: #202932;
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(52, 66, 81, 0.5);
}

.badge-info {
    color: #212529;
    background-color: #8a9bad;
}

a.badge-info:hover,
a.badge-info:focus {
    color: #212529;
    background-color: #6c8198;
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(138, 155, 173, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(138, 155, 173, 0.5);
}

.badge-warning {
    color: #fff;
    background-color: #e12d2d;
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #fff;
    background-color: #c01b1b;
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
}

.badge-danger {
    color: #fff;
    background-color: #e12d2d;
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #c01b1b;
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(225, 45, 45, 0.5);
}

.badge-light {
    color: #fff;
    background-color: #56697e;
}

a.badge-light:hover,
a.badge-light:focus {
    color: #fff;
    background-color: #415060;
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(86, 105, 126, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(86, 105, 126, 0.5);
}

.badge-dark {
    color: #fff;
    background-color: #354251;
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #212932;
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(53, 66, 81, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(53, 66, 81, 0.5);
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem;
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #853602;
    background-color: #ffe1cd;
    border-color: #ffd4b9;
}

.alert-primary hr {
    border-top-color: #ffc4a0;
}

.alert-primary .alert-link {
    color: #532201;
}

.alert-secondary {
    color: #303031;
    background-color: #dededf;
    border-color: #d1d1d2;
}

.alert-secondary hr {
    border-top-color: #c4c4c5;
}

.alert-secondary .alert-link {
    color: #171717;
}

.alert-success {
    color: #1b222a;
    background-color: #d6d9dc;
    border-color: #c6cace;
}

.alert-success hr {
    border-top-color: #b8bdc2;
}

.alert-success .alert-link {
    color: #07090b;
}

.alert-info {
    color: #48515a;
    background-color: #e8ebef;
    border-color: #dee3e8;
}

.alert-info hr {
    border-top-color: #cfd6de;
}

.alert-info .alert-link {
    color: #31383e;
}

.alert-warning {
    color: #751717;
    background-color: #f9d5d5;
    border-color: #f7c4c4;
}

.alert-warning hr {
    border-top-color: #f4aeae;
}

.alert-warning .alert-link {
    color: #4a0f0f;
}

.alert-danger {
    color: #751717;
    background-color: #f9d5d5;
    border-color: #f7c4c4;
}

.alert-danger hr {
    border-top-color: #f4aeae;
}

.alert-danger .alert-link {
    color: #4a0f0f;
}

.alert-light {
    color: #2d3742;
    background-color: #dde1e5;
    border-color: #d0d5db;
}

.alert-light hr {
    border-top-color: #c2c8d0;
}

.alert-light .alert-link {
    color: #181e24;
}

.alert-dark {
    color: #1c222a;
    background-color: #d7d9dc;
    border-color: #c6cace;
}

.alert-dark hr {
    border-top-color: #b8bdc2;
}

.alert-dark .alert-link {
    color: #08090b;
}

@-webkit-keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }

    to {
        background-position: 0 0;
    }
}

.progress {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
}

.progress-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #ff6704;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        -webkit-transition: none;
        transition: none;
    }
}

.progress-bar-striped {
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        -webkit-animation: none;
        animation: none;
    }
}

.media {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.media-body {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.list-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}

.list-group-item-action:active {
    color: #56697e;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ff6704;
    border-color: #ff6704;
}

.list-group-item + .list-group-item {
    border-top-width: 0;
}

.list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-sm .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-md .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-lg .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-left-radius: 0;
    }

    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0;
    }

    .list-group-horizontal-xl .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }

    .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #853602;
    background-color: #ffd4b9;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #853602;
    background-color: #ffc4a0;
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #853602;
    border-color: #853602;
}

.list-group-item-secondary {
    color: #303031;
    background-color: #d1d1d2;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #303031;
    background-color: #c4c4c5;
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #303031;
    border-color: #303031;
}

.list-group-item-success {
    color: #1b222a;
    background-color: #c6cace;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #1b222a;
    background-color: #b8bdc2;
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #1b222a;
    border-color: #1b222a;
}

.list-group-item-info {
    color: #48515a;
    background-color: #dee3e8;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #48515a;
    background-color: #cfd6de;
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #48515a;
    border-color: #48515a;
}

.list-group-item-warning {
    color: #751717;
    background-color: #f7c4c4;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #751717;
    background-color: #f4aeae;
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #751717;
    border-color: #751717;
}

.list-group-item-danger {
    color: #751717;
    background-color: #f7c4c4;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #751717;
    background-color: #f4aeae;
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #751717;
    border-color: #751717;
}

.list-group-item-light {
    color: #2d3742;
    background-color: #d0d5db;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #2d3742;
    background-color: #c2c8d0;
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #2d3742;
    border-color: #2d3742;
}

.list-group-item-dark {
    color: #1c222a;
    background-color: #c6cace;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1c222a;
    background-color: #b8bdc2;
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1c222a;
    border-color: #1c222a;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.close:hover {
    color: #000;
    text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: 0.75;
    outline: none;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
}

a.close.disabled {
    pointer-events: none;
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: 0.875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: 0.25rem;
}

.toast:not(:last-child) {
    margin-bottom: 0.75rem;
}

.toast.showing {
    opacity: 1;
}

.toast.show {
    display: block;
    opacity: 1;
}

.toast.hide {
    display: none;
}

.toast-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.toast-body {
    padding: 0.75rem;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;

    &.fade.show {
        display: flex !important;
    }
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

.modal.fade .modal-dialog {
    margin: auto;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none;
    }
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    max-height: -webkit-calc(100% - 1rem);
    max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
    max-height: -webkit-calc(100vh - 1rem);
    max-height: calc(100vh - 1rem);
    overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: -webkit-calc(100% - 1rem);
    min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
    display: block;
    height: -webkit-calc(100vh - 1rem);
    height: calc(100vh - 1rem);
    content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -moz-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: -webkit-calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: -webkit-calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0.75rem;
    border-bottom-right-radius: -webkit-calc(0.3rem - 1px);
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: -webkit-calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
    margin: 0.25rem;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    .modal-dialog-scrollable {
        max-height: -webkit-calc(100% - 3.5rem);
        max-height: calc(100% - 3.5rem);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: -webkit-calc(100vh - 3.5rem);
        max-height: calc(100vh - 3.5rem);
    }

    .modal-dialog-centered {
        min-height: -webkit-calc(100% - 3.5rem);
        min-height: calc(100% - 3.5rem);
    }

    .modal-dialog-centered::before {
        height: -webkit-calc(100vh - 3.5rem);
        height: calc(100vh - 3.5rem);
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal {
    padding-right: 0 !important;
}

.modal-collect {
    text-align: center;
}

.modal-collect .icon {
    display: inline-block;
    font-size: 6em;
    margin-bottom: 0.8rem;
}

.modal-collect .icon-correct {
    color: #289f77;
}

.modal-collect .icon-close-circle {
    color: #e12d2d;
}

.modal-collect p {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.modal-collect .btn {
    max-width: 120px;
}

.modal .contact-modal .modal-content {
    width: 115%;
}

.modal .company-contact-modal .modal-content {
    width: 140%;
}

.modal .add-booking-modal .modal-content {
    width: 680px;
    left: -170px;
}

.modal .manual-payment-modal .modal-content {
    width: 600px;
    left: -110px;
}


.modal .work-list-modal .modal-content,
.modal .edit-booking-modal .modal-content {
    width: 850px;
    left: -250px;
}

.modal .pdf-preview-modal .modal-content {
    width: 752px;
    margin-top: 32px;
    left: -170px;
}

.modal .cancel-booking-modal .modal-content {
    width: 365px;
    height: 339px;
}

.modal .add-booking-b2c-modal .modal-content {
    width: 932px;
    left: -285px;
}

.modal .pass-booking-to-owner-modal .modal-content {
    width: 850px;
    min-height: 360px;
    left: -250px;
    border-radius: 10px;
    border-left: 25px solid #ff6704;

    &::before {
        display: block;
        height: 100%;
        width: 1px;
        content: '';
        border-left: dashed 1px #dadada;
        position: absolute;
        right: 275px;
        bottom: 0px;
    }
}

.modal .add-incentive-modal .modal-content {
    width: 998px;
    transform: translateX(-30%);
}

.modal {
    .contact-modal,
    .add-booking-modal,
    .edit-booking-modal,
    .success-modal,
    .cancel-booking-modal,
    .pass-booking-to-owner-modal,
    .product-owner-modal,
    .customer-info-modal,
    .cancel-tw-booking {
        .modal-header {
            button.close {
                margin-top: 0;
                margin-bottom: -0.5rem;
                padding: 0.5rem 1rem;
                opacity: 1;
                outline: none;

                span {
                    color: #646e79;
                    outline: none;
                }
            }
        }

        h4 {
            color: #354251;
            font-size: 16px;
            font-weight: bold;
        }
    }

    .program-period-not-found-modal {
        width: 420px !important;
        max-width: 420px !important;
    }

    .product-owner-modal {
        width: 600px !important;
        max-width: 600px !important;
    }

    .cancel-tw-booking {
        width: 600px !important;
        max-width: 600px !important;
    }

    .campaign-promote-modal {
        width: 620px !important;
        max-width: 620px !important;

        .modal-content {
            border-radius: 10px;
        }
    }

    .confirm-payment-modal {
        width: 405px !important;
        max-width: 405px !important;
        .modal-content {
            border-radius: 10px;
        }
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}

.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
    margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow {
    bottom: -webkit-calc(-0.5rem - 1px);
    bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
    margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
    left: -webkit-calc(-0.5rem - 1px);
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
    margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow {
    top: -webkit-calc(-0.5rem - 1px);
    top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: '';
    border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
    margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
    right: -webkit-calc(-0.5rem - 1px);
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff;
}

.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: -webkit-calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: -webkit-calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 0.5rem 0.75rem;
    color: #56697e;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: '';
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        -webkit-transition: none;
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.carousel-fade .carousel-item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    -webkit-transition: opacity 0s 0.6s;
    transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        -webkit-transition: none;
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        -webkit-transition: none;
        transition: none;
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        -webkit-transition: none;
        transition: none;
    }
}

.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow 0.75s linear infinite;
    animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.bg-primary {
    background-color: #ff6704 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #d05200 !important;
}

.bg-secondary {
    background-color: #5c5c5e !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #434344 !important;
}

.bg-success {
    background-color: #344251 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #202932 !important;
}

.bg-info {
    background-color: #8a9bad !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #6c8198 !important;
}

.bg-warning {
    background-color: #e12d2d !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #c01b1b !important;
}

.bg-danger {
    background-color: #e12d2d !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #c01b1b !important;
}

.bg-light {
    background-color: #56697e !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #415060 !important;
}

.bg-dark {
    background-color: #354251 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #212932 !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.border-top {
    border-top: 1px solid #dee2e6 !important;
}

.border-right {
    border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
    border-left: 1px solid #dee2e6 !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #ff6704 !important;
}

.border-secondary {
    border-color: #5c5c5e !important;
}

.border-success {
    border-color: #344251 !important;
}

.border-info {
    border-color: #8a9bad !important;
}

.border-warning {
    border-color: #e12d2d !important;
}

.border-danger {
    border-color: #e12d2d !important;
}

.border-light {
    border-color: #56697e !important;
}

.border-dark {
    border-color: #354251 !important;
}

.border-white {
    border-color: #fff !important;
}

.rounded-sm {
    border-radius: 0.2rem !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
    border-radius: 0.3rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: '';
}

.d-none {
    display: none !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.d-inline-flex {
    display: -webkit-inline-box !important;
    display: -webkit-inline-flex !important;
    display: -moz-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important;
    }

    .d-sm-inline {
        display: inline !important;
    }

    .d-sm-inline-block {
        display: inline-block !important;
    }

    .d-sm-block {
        display: block !important;
    }

    .d-sm-table {
        display: table !important;
    }

    .d-sm-table-row {
        display: table-row !important;
    }

    .d-sm-table-cell {
        display: table-cell !important;
    }

    .d-sm-flex {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display: -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important;
    }

    .d-md-inline {
        display: inline !important;
    }

    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    .d-md-table-row {
        display: table-row !important;
    }

    .d-md-table-cell {
        display: table-cell !important;
    }

    .d-md-flex {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-md-inline-flex {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display: -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    .d-lg-inline {
        display: inline !important;
    }

    .d-lg-inline-block {
        display: inline-block !important;
    }

    .d-lg-block {
        display: block !important;
    }

    .d-lg-table {
        display: table !important;
    }

    .d-lg-table-row {
        display: table-row !important;
    }

    .d-lg-table-cell {
        display: table-cell !important;
    }

    .d-lg-flex {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display: -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important;
    }

    .d-xl-inline {
        display: inline !important;
    }

    .d-xl-inline-block {
        display: inline-block !important;
    }

    .d-xl-block {
        display: block !important;
    }

    .d-xl-table {
        display: table !important;
    }

    .d-xl-table-row {
        display: table-row !important;
    }

    .d-xl-table-cell {
        display: table-cell !important;
    }

    .d-xl-flex {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display: -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
    }

    .d-print-inline-flex {
        display: -webkit-inline-box !important;
        display: -webkit-inline-flex !important;
        display: -moz-inline-box !important;
        display: -ms-inline-flexbox !important;
        display: inline-flex !important;
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

.embed-responsive::before {
    display: block;
    content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

.embed-responsive-4by3::before {
    padding-top: 75%;
}

.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: row !important;
    -moz-box-orient: horizontal !important;
    -moz-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.flex-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
    -webkit-flex-direction: column !important;
    -moz-box-orient: vertical !important;
    -moz-box-direction: normal !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
}

.flex-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: row-reverse !important;
    -moz-box-orient: horizontal !important;
    -moz-box-direction: reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
    -webkit-flex-direction: column-reverse !important;
    -moz-box-orient: vertical !important;
    -moz-box-direction: reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
}

.flex-wrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.flex-nowrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
}

.flex-fill {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
    -moz-box-flex: 1 !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
}

.flex-grow-0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex-grow: 0 !important;
    -moz-box-flex: 0 !important;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
}

.flex-grow-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex-grow: 1 !important;
    -moz-box-flex: 1 !important;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    -webkit-flex-shrink: 0 !important;
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    -webkit-flex-shrink: 1 !important;
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
}

.justify-content-start {
    -webkit-box-pack: start !important;
    -webkit-justify-content: flex-start !important;
    -moz-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
}

.justify-content-end {
    -webkit-box-pack: end !important;
    -webkit-justify-content: flex-end !important;
    -moz-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.justify-content-center {
    -webkit-box-pack: center !important;
    -webkit-justify-content: center !important;
    -moz-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.justify-content-between {
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -moz-box-pack: justify !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
}

.justify-content-around {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
}

.align-items-start {
    -webkit-box-align: start !important;
    -webkit-align-items: flex-start !important;
    -moz-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
}

.align-items-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    -moz-box-align: end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
}

.align-items-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -moz-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.align-items-baseline {
    -webkit-box-align: baseline !important;
    -webkit-align-items: baseline !important;
    -moz-box-align: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
}

.align-items-stretch {
    -webkit-box-align: stretch !important;
    -webkit-align-items: stretch !important;
    -moz-box-align: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
}

.align-content-start {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
}

.align-content-end {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
}

.align-content-center {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important;
}

.align-content-between {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
}

.align-content-around {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
}

.align-content-stretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
}

.align-self-auto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
}

.align-self-start {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
}

.align-self-end {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
}

.align-self-center {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
}

.align-self-baseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
}

.align-self-stretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
}

@media (min-width: 576px) {
    .flex-sm-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-sm-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: row-reverse !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-sm-wrap {
        -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-sm-nowrap {
        -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-sm-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-sm-fill {
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
        -moz-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-sm-grow-0 {
        -webkit-box-flex: 0 !important;
        -webkit-flex-grow: 0 !important;
        -moz-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-sm-grow-1 {
        -webkit-box-flex: 1 !important;
        -webkit-flex-grow: 1 !important;
        -moz-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-sm-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-sm-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-sm-start {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
        -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-sm-end {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
        -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-sm-center {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
        -moz-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify !important;
        -webkit-justify-content: space-between !important;
        -moz-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-sm-around {
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-sm-start {
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
        -moz-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-sm-end {
        -webkit-box-align: end !important;
        -webkit-align-items: flex-end !important;
        -moz-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-sm-center {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -moz-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline !important;
        -webkit-align-items: baseline !important;
        -moz-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch !important;
        -webkit-align-items: stretch !important;
        -moz-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-sm-start {
        -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-sm-end {
        -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-sm-center {
        -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-sm-between {
        -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-sm-around {
        -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-sm-stretch {
        -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-sm-auto {
        -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-sm-start {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-sm-end {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-sm-center {
        -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-sm-baseline {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-sm-stretch {
        -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-md-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: row-reverse !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-md-wrap {
        -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-md-nowrap {
        -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-md-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-md-fill {
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
        -moz-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-md-grow-0 {
        -webkit-box-flex: 0 !important;
        -webkit-flex-grow: 0 !important;
        -moz-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-md-grow-1 {
        -webkit-box-flex: 1 !important;
        -webkit-flex-grow: 1 !important;
        -moz-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-md-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-md-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-md-start {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
        -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-md-end {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
        -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-md-center {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
        -moz-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-md-between {
        -webkit-box-pack: justify !important;
        -webkit-justify-content: space-between !important;
        -moz-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-md-around {
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-md-start {
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
        -moz-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-md-end {
        -webkit-box-align: end !important;
        -webkit-align-items: flex-end !important;
        -moz-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-md-center {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -moz-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline !important;
        -webkit-align-items: baseline !important;
        -moz-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch !important;
        -webkit-align-items: stretch !important;
        -moz-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-md-start {
        -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-md-end {
        -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-md-center {
        -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-md-between {
        -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-md-around {
        -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-md-stretch {
        -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-md-auto {
        -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-md-start {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-md-end {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-md-center {
        -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-md-baseline {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-md-stretch {
        -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-lg-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: row-reverse !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-lg-wrap {
        -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-lg-nowrap {
        -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-lg-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-lg-fill {
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
        -moz-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-lg-grow-0 {
        -webkit-box-flex: 0 !important;
        -webkit-flex-grow: 0 !important;
        -moz-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-lg-grow-1 {
        -webkit-box-flex: 1 !important;
        -webkit-flex-grow: 1 !important;
        -moz-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-lg-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-lg-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-lg-start {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
        -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-lg-end {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
        -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-lg-center {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
        -moz-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify !important;
        -webkit-justify-content: space-between !important;
        -moz-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-lg-around {
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-lg-start {
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
        -moz-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-lg-end {
        -webkit-box-align: end !important;
        -webkit-align-items: flex-end !important;
        -moz-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-lg-center {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -moz-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline !important;
        -webkit-align-items: baseline !important;
        -moz-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch !important;
        -webkit-align-items: stretch !important;
        -moz-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-lg-start {
        -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-lg-end {
        -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-lg-between {
        -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-lg-around {
        -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-lg-stretch {
        -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-lg-auto {
        -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-lg-start {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-lg-end {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-lg-center {
        -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-lg-baseline {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-lg-stretch {
        -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: row !important;
        flex-direction: row !important;
    }

    .flex-xl-column {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: column !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: normal !important;
        -ms-flex-direction: column !important;
        flex-direction: column !important;
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: row-reverse !important;
        -moz-box-orient: horizontal !important;
        -moz-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important;
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical !important;
        -webkit-box-direction: reverse !important;
        -webkit-flex-direction: column-reverse !important;
        -moz-box-orient: vertical !important;
        -moz-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important;
    }

    .flex-xl-wrap {
        -webkit-flex-wrap: wrap !important;
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
    }

    .flex-xl-nowrap {
        -webkit-flex-wrap: nowrap !important;
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
    }

    .flex-xl-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse !important;
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
    }

    .flex-xl-fill {
        -webkit-box-flex: 1 !important;
        -webkit-flex: 1 1 auto !important;
        -moz-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important;
    }

    .flex-xl-grow-0 {
        -webkit-box-flex: 0 !important;
        -webkit-flex-grow: 0 !important;
        -moz-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important;
    }

    .flex-xl-grow-1 {
        -webkit-box-flex: 1 !important;
        -webkit-flex-grow: 1 !important;
        -moz-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important;
    }

    .flex-xl-shrink-0 {
        -webkit-flex-shrink: 0 !important;
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
    }

    .flex-xl-shrink-1 {
        -webkit-flex-shrink: 1 !important;
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
    }

    .justify-content-xl-start {
        -webkit-box-pack: start !important;
        -webkit-justify-content: flex-start !important;
        -moz-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    .justify-content-xl-end {
        -webkit-box-pack: end !important;
        -webkit-justify-content: flex-end !important;
        -moz-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    .justify-content-xl-center {
        -webkit-box-pack: center !important;
        -webkit-justify-content: center !important;
        -moz-box-pack: center !important;
        -ms-flex-pack: center !important;
        justify-content: center !important;
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify !important;
        -webkit-justify-content: space-between !important;
        -moz-box-pack: justify !important;
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
    }

    .justify-content-xl-around {
        -webkit-justify-content: space-around !important;
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
    }

    .align-items-xl-start {
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
        -moz-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }

    .align-items-xl-end {
        -webkit-box-align: end !important;
        -webkit-align-items: flex-end !important;
        -moz-box-align: end !important;
        -ms-flex-align: end !important;
        align-items: flex-end !important;
    }

    .align-items-xl-center {
        -webkit-box-align: center !important;
        -webkit-align-items: center !important;
        -moz-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline !important;
        -webkit-align-items: baseline !important;
        -moz-box-align: baseline !important;
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch !important;
        -webkit-align-items: stretch !important;
        -moz-box-align: stretch !important;
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
    }

    .align-content-xl-start {
        -webkit-align-content: flex-start !important;
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
    }

    .align-content-xl-end {
        -webkit-align-content: flex-end !important;
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
    }

    .align-content-xl-center {
        -webkit-align-content: center !important;
        -ms-flex-line-pack: center !important;
        align-content: center !important;
    }

    .align-content-xl-between {
        -webkit-align-content: space-between !important;
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
    }

    .align-content-xl-around {
        -webkit-align-content: space-around !important;
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
    }

    .align-content-xl-stretch {
        -webkit-align-content: stretch !important;
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
    }

    .align-self-xl-auto {
        -webkit-align-self: auto !important;
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
    }

    .align-self-xl-start {
        -webkit-align-self: flex-start !important;
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
    }

    .align-self-xl-end {
        -webkit-align-self: flex-end !important;
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
    }

    .align-self-xl-center {
        -webkit-align-self: center !important;
        -ms-flex-item-align: center !important;
        align-self: center !important;
    }

    .align-self-xl-baseline {
        -webkit-align-self: baseline !important;
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
    }

    .align-self-xl-stretch {
        -webkit-align-self: stretch !important;
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important;
    }

    .float-md-right {
        float: right !important;
    }

    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important;
    }

    .float-lg-right {
        float: right !important;
    }

    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important;
    }

    .float-xl-right {
        float: right !important;
    }

    .float-xl-none {
        float: none !important;
    }
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

.shadow-sm {
    -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.mh-100 {
    max-height: 100% !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: '';
    background-color: transparent;
}

.m-0 {
    margin: 0 !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

.pt-0,
.py-0 {
    padding-top: 0 !important;
}

.pr-0,
.px-0 {
    padding-right: 0 !important;
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

.m-n1 {
    margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
    margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
    margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
    margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
    margin-left: -0.25rem !important;
}

.m-n2 {
    margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
    margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
    margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
    margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
    margin-left: -0.5rem !important;
}

.m-n3 {
    margin: -1rem !important;
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important;
}

.m-n4 {
    margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important;
}

.m-n5 {
    margin: -3rem !important;
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto,
.my-auto {
    margin-top: auto !important;
}

.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important;
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    .m-sm-1 {
        margin: 0.25rem !important;
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    .m-sm-2 {
        margin: 0.5rem !important;
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    .m-sm-3 {
        margin: 1rem !important;
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    .m-sm-4 {
        margin: 1.5rem !important;
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    .m-sm-5 {
        margin: 3rem !important;
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    .p-sm-0 {
        padding: 0 !important;
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    .p-sm-1 {
        padding: 0.25rem !important;
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    .p-sm-2 {
        padding: 0.5rem !important;
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    .p-sm-3 {
        padding: 1rem !important;
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    .p-sm-4 {
        padding: 1.5rem !important;
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    .p-sm-5 {
        padding: 3rem !important;
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    .m-sm-n1 {
        margin: -0.25rem !important;
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -0.25rem !important;
    }

    .m-sm-n2 {
        margin: -0.5rem !important;
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -0.5rem !important;
    }

    .m-sm-n3 {
        margin: -1rem !important;
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important;
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important;
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important;
    }

    .m-sm-n4 {
        margin: -1.5rem !important;
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important;
    }

    .m-sm-n5 {
        margin: -3rem !important;
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important;
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important;
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important;
    }

    .m-sm-auto {
        margin: auto !important;
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    .m-md-n1 {
        margin: -0.25rem !important;
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -0.25rem !important;
    }

    .m-md-n2 {
        margin: -0.5rem !important;
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -0.5rem !important;
    }

    .m-md-n3 {
        margin: -1rem !important;
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important;
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important;
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important;
    }

    .m-md-n4 {
        margin: -1.5rem !important;
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important;
    }

    .m-md-n5 {
        margin: -3rem !important;
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important;
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important;
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important;
    }

    .m-md-auto {
        margin: auto !important;
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important;
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    .m-lg-1 {
        margin: 0.25rem !important;
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    .m-lg-2 {
        margin: 0.5rem !important;
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    .m-lg-3 {
        margin: 1rem !important;
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    .m-lg-4 {
        margin: 1.5rem !important;
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    .m-lg-5 {
        margin: 3rem !important;
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    .p-lg-0 {
        padding: 0 !important;
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    .p-lg-1 {
        padding: 0.25rem !important;
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    .p-lg-2 {
        padding: 0.5rem !important;
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    .p-lg-3 {
        padding: 1rem !important;
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    .p-lg-4 {
        padding: 1.5rem !important;
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    .p-lg-5 {
        padding: 3rem !important;
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    .m-lg-n1 {
        margin: -0.25rem !important;
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -0.25rem !important;
    }

    .m-lg-n2 {
        margin: -0.5rem !important;
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -0.5rem !important;
    }

    .m-lg-n3 {
        margin: -1rem !important;
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important;
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important;
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important;
    }

    .m-lg-n4 {
        margin: -1.5rem !important;
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important;
    }

    .m-lg-n5 {
        margin: -3rem !important;
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important;
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important;
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important;
    }

    .m-lg-auto {
        margin: auto !important;
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important;
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    .m-xl-1 {
        margin: 0.25rem !important;
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    .m-xl-2 {
        margin: 0.5rem !important;
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    .m-xl-3 {
        margin: 1rem !important;
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    .m-xl-4 {
        margin: 1.5rem !important;
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    .m-xl-5 {
        margin: 3rem !important;
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    .p-xl-0 {
        padding: 0 !important;
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    .p-xl-1 {
        padding: 0.25rem !important;
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    .p-xl-2 {
        padding: 0.5rem !important;
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    .p-xl-3 {
        padding: 1rem !important;
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    .p-xl-4 {
        padding: 1.5rem !important;
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    .p-xl-5 {
        padding: 3rem !important;
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    .m-xl-n1 {
        margin: -0.25rem !important;
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -0.25rem !important;
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -0.25rem !important;
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -0.25rem !important;
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -0.25rem !important;
    }

    .m-xl-n2 {
        margin: -0.5rem !important;
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -0.5rem !important;
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -0.5rem !important;
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -0.5rem !important;
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -0.5rem !important;
    }

    .m-xl-n3 {
        margin: -1rem !important;
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important;
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important;
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important;
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important;
    }

    .m-xl-n4 {
        margin: -1.5rem !important;
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important;
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important;
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important;
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important;
    }

    .m-xl-n5 {
        margin: -3rem !important;
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important;
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important;
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important;
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important;
    }

    .m-xl-auto {
        margin: auto !important;
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.text-justify {
    text-align: justify !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.font-weight-light {
    font-weight: 300 !important;
}

.font-weight-lighter {
    font-weight: lighter !important;
}

.font-weight-normal {
    font-weight: 400 !important;
}

.font-weight-bold {
    font-weight: 700 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
}

.font-italic {
    font-style: italic !important;
}

.text-white {
    color: #fff !important;
}

.text-primary {
    color: #ff6704 !important;
}

a.text-primary:hover,
a.text-primary:focus {
    color: #b74800 !important;
}

.text-secondary {
    color: #5c5c5e !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #363637 !important;
}

.text-success {
    color: #344251 !important;
}

a.text-success:hover,
a.text-success:focus {
    color: #161c22 !important;
}

.text-info {
    color: #8a9bad !important;
}

a.text-info:hover,
a.text-info:focus {
    color: #61758a !important;
}

.text-warning {
    color: #e12d2d !important;
}

a.text-warning:hover,
a.text-warning:focus {
    color: #a91818 !important;
}

.text-danger {
    color: #e12d2d !important;
}

a.text-danger:hover,
a.text-danger:focus {
    color: #a91818 !important;
}

.text-light {
    color: #56697e !important;
}

a.text-light:hover,
a.text-light:focus {
    color: #374351 !important;
}

.text-dark {
    color: #354251 !important;
}

a.text-dark:hover,
a.text-dark:focus {
    color: #171c23 !important;
}

.text-body {
    color: #56697e !important;
}

.text-muted {
    color: #8a9bad !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important;
}

.text-reset {
    color: inherit !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: ' (' attr(title) ')';
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid #adb5bd;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: #fff !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #dee2e6 !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
        border-color: #dee2e6;
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #dee2e6;
    }
}

@font-face {
    font-family: 'icon';
    src: url('~src/assets/fonts/icon.eot?z1f7nh');
    src: url('~src/assets/fonts/icon.eot?z1f7nh#iefix') format('embedded-opentype'),
        url('~src/assets/fonts/icon.ttf?z1f7nh') format('truetype'),
        url('~src/assets/fonts/icon.woff?z1f7nh') format('woff'),
        url('~src/assets/fonts/icon.svg?z1f7nh#icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-data-people:before {
    content: '\e901';
}

.icon-data-product:before {
    content: '\e902';
}

.icon-arrow-back:before {
    content: '\e903';
}

.icon-arrow-next:before {
    content: '\e904';
}

.icon-condition:before {
    content: '\e900';
}

.icon-arrow-down:before {
    content: '\e90a';
}

.icon-arrow-up:before {
    content: '\e90b';
}

.icon-bars:before {
    content: '\e90c';
}

.icon-calendar:before {
    content: '\e90d';
}

.icon-catalouge:before {
    content: '\e90e';
}

.icon-check-solid:before {
    content: '\e90f';
}

.icon-check-square:before {
    content: '\e910';
}

.icon-clock:before {
    content: '\e911';
}

.icon-close-circle:before {
    content: '\e912';
}

.icon-close1:before {
    content: '\e913';
}

.icon-correct:before {
    content: '\e91b';
}

.icon-dashboard:before {
    content: '\e91c';
}

.icon-detail:before {
    content: '\e91d';
}

.icon-flight-arrival:before {
    content: '\e91e';
}

.icon-flight-go:before {
    content: '\e91f';
}

.icon-garbage:before {
    content: '\e920';
}

.icon-history:before {
    content: '\e921';
}

.icon-location:before {
    content: '\e922';
}

.icon-location-blue:before {
    content: url('~src/assets/img/icon/icon-location-blue.svg');
}

.icon-logout:before {
    content: '\e923';
}

.icon-management:before {
    content: '\e924';
}

.icon-moon:before {
    content: '\e925';
}

.icon-plus-circle:before {
    content: '\e926';
}

.icon-report:before {
    content: '\e927';
}

.icon-report-download:before {
    content: url('~src/assets/img/icon/icon-report-download.svg');
}

.icon-star:before {
    content: '\e928';
}

.icon-info:before {
    content: url('~src/assets/img/icon/icon-info.svg');
}

.icon-settings:before {
    content: url('~src/assets/img/icon/icon-settings.svg');
    filter: invert(100%);
}

.icon-settings-orange:before {
    content: url('~src/assets/img/icon/icon-settings-orange.svg');
}

.icon-booking-b2b:before {
    content: url('~src/assets/img/icon/icon-booking-b2b.svg');
}

.icon-booking-b2b-w:before {
    content: url('~src/assets/img/icon/icon-booking-b2b-w.svg');
}

.icon-report-booking:before {
    content: url('~src/assets/img/icon/icon-report-booking.svg');
}

.icon-report-booking-w:before {
    content: url('~src/assets/img/icon/icon-report-booking-w.svg');
}

.icon-portfolio:before {
    content: url('~src/assets/img/icon/icon-portfolio.svg');
}

.icon-file:before {
    content: url('~src/assets/img/icon/icon-file.svg');
}

.icon-file-orange:before {
    content: url('~src/assets/img/icon/icon-file-orange.svg');
}

.icon-notification:before {
    content: url('~src/assets/img/icon/icon-notification.svg');
    position: relative;
    top: 1px;
}

.icon-website:before {
    content: url('~src/assets/img/icon/icon-website.svg');
    position: relative;
    top: 1px;
}

.icon-line:before {
    content: url('~src/assets/img/icon/icon-line.svg');
    position: relative;
    top: 2px;
}

.icon-phone:before {
    content: url('~src/assets/img/icon/icon-phone.svg');
    position: relative;
    top: 1px;
}

.icon-excel:before {
    content: url('~src/assets/img/icon/icon-excel-download.svg');
}

.icon-excel-non:before {
    content: url('~src/assets/img/icon/icon-excel-download-non.svg');
}

.icon-exclamation-mark:before {
    content: url('~src/assets/img/icon/icon-exclamation-mark.svg');
}

.icon-status:before {
    content: '';
    display: inline-flex;
    height: 24px;
    width: 27px;
    background-size: 24px 27px;
    background-image: url('~src/assets/img/icon/icon-status.svg');
    background-repeat: no-repeat;
}

.icon-add-booking-orange:before {
    position: relative;
    top: 5px;
    content: '';
    display: inline-flex;
    height: 32px;
    width: 35px;
    background-size: 32px 35px;
    background-image: url('~src/assets/img/icon/icon-add-booking.svg');
    background-repeat: no-repeat;
}

.icon-status-orange:before {
    position: relative;
    top: 2px;
    content: '';
    display: inline-flex;
    height: 32px;
    width: 35px;
    background-size: 32px 35px;
    background-image: url('~src/assets/img/icon/icon-status-orange.svg');
    background-repeat: no-repeat;
}

.icon-booking-payment:before {
    position: relative;
    top: 3px;
    content: '';
    display: inline-flex;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-image: url('~src/assets/img/icon/icon-booking-payment.svg');
    background-repeat: no-repeat;
}

.icon-booking-travel:before {
    position: relative;
    top: 3px;
    content: '';
    display: inline-flex;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-image: url('~src/assets/img/icon/icon-booking-travel.svg');
    background-repeat: no-repeat;
}

.icon-booking-bag-travel:before {
    position: relative;
    top: 3px;
    content: '';
    display: inline-flex;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-image: url('~src/assets/img/icon/icon-booking-bag-travel.svg');
    background-repeat: no-repeat;
}

.icon-booking-bag-travel-person:before {
    position: relative;
    top: 3px;
    content: '';
    display: inline-flex;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-image: url('~src/assets/img/icon/icon-booking-bag-travel-person.svg');
    background-repeat: no-repeat;
}

.icon-booking-cancel:before {
    position: relative;
    top: 3px;
    content: '';
    display: inline-flex;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-image: url('~src/assets/img/icon/icon-booking-cancel.svg');
    background-repeat: no-repeat;
}

.icon-attachment-clip:before {
    position: relative;
    top: 2px;
    content: '';
    display: inline-flex;
    height: 15px;
    width: 15px;
    background-size: 15px 15px;
    background-image: url('~src/assets/img/icon/icon-attachment-clip.svg');
    background-repeat: no-repeat;
}

.icon-coupon {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-coupon.svg');
    background-repeat: no-repeat;
}

.icon-ticket {
    width: 20px;
    height: 17px;
    background-size: 20px 20px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-ticket.png');
    background-repeat: no-repeat;
}

.icon-email {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-email.svg');
    background-repeat: no-repeat;
}

.icon-search {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-search.svg');
    background-repeat: no-repeat;
}

.icon-waiting-confirmation {
    width: 17px;
    height: 17px;
    background-size: 20px 20px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-waiting-confirmation.svg');
    background-repeat: no-repeat;
}

.icon-check-mark {
    width: 20px;
    height: 17px;
    background-size: 20px 20px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-check-mark.svg');
    background-repeat: no-repeat;
}

.icon-check-mark-green {
    width: 12px;
    height: 11px;
    background-size: 13px 11px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-check-mark-green.svg');
    background-repeat: no-repeat;
}

.icon-check-mark-bg-green {
    width: 12px;
    height: 11px;
    background-size: 13px 11px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-check-mark-bg-green.svg');
    background-repeat: no-repeat;
}

.icon-check-mark-bg-grey {
    width: 12px;
    height: 11px;
    background-size: 13px 11px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-check-mark-bg-grey.svg');
    background-repeat: no-repeat;
}

.icon-edit {
    width: 12px;
    height: 11px;
    background-size: 13px 11px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-edit.svg');
    background-repeat: no-repeat;
}
.icon-trash {
    width: 12px;
    height: 11px;
    background-size: 13px 11px;
    display: inline-flex;
    background-image: url('~src/assets/img/icon/icon-trash-red.svg');
    background-repeat: no-repeat;
}

/*
    DEMO STYLE
*/
body {
    background: #f2f2f2;
    overflow-x: hidden;
    color: #354251;
    font-size: 14px;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.navbar {
    padding: 5px 0;
    background: #ff6704;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

h2 i {
    display: inline-block;
    font-size: 1em;
    margin-right: 10px;
    position: relative;
    top: 2px;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

a[data-toggle='collapse'] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.btn {
    font-size: 14px;
}

.btn-cancel {
    width: 100px;
    border: 1px solid #56697e;
    margin-left: 6px;
}

.copy-link-success-modal {
    button.close {
        position: absolute;
        top: 14px;
        right: 24px;
    }

    .icon-success {
        background-image: url('/assets/img/icon/icons-modal.png');
        background-repeat: no-repeat;
        background-position: -120px 0px;

        width: 60px;
        height: 60px;
        display: block;
        margin: 0 auto;
    }

    .btn-close {
        position: relative;
        top: 0.5rem;
        border-color: #5c5c5e !important;
        background-color: #5c5c5e !important;
        color: #fff;
        font-size: 14px;
        width: 120px;
    }
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
    width: -webkit-calc(100% - 210px);
    width: calc(100% - 210px);
    min-height: 100vh;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
}

#content.active {
    width: 100%;
}

.content {
    padding: 25px;
}

.navbar span {
    font-size: 20px;
    padding: 0;
    position: relative;
    top: -3px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }

    #sidebar.active {
        margin-left: 0 !important;
    }

    #content {
        width: 100%;
    }

    #content.active {
        width: -webkit-calc(100% - 200px);
        width: calc(100% - 200px);
    }

    #sidebarCollapse span {
        display: none;
    }
}

.search-data .col-form-label {
    width: 12rem;
}

.form-inline {
    height: 100%;
}

.head-group {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 2.6rem;
    margin-top: 2.6rem;
}

.head-group .title {
    font-size: 18px;
    font-weight: 500;
}

.add-img {
    text-align: center;
}

.add-img .upload-img {
    background-color: #fffbf8;
    border: dashed 3px #c1bebe;
}

.add-img .upload-img input {
    display: none;
    height: 0;
}

.add-img .upload-img img {
    display: inherit;
}

.add-img .upload-img label {
    width: 100%;
    margin-bottom: 0;
    display: block;
}

.add-img .upload-img div {
    position: relative;
    text-align: center;
    min-height: 190px;
    background: url(~src/assets/img/img-default.png) no-repeat center center;
    background-size: 60px auto;
    margin-bottom: 20px;
}

.add-img .upload-img div b {
    position: absolute;
    left: 50%;
    top: -webkit-calc(100% - 65px);
    top: calc(100% - 65px);
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    min-width: 180px;
}

.add-img .upload-img div p {
    width: 100%;
    padding: 0 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 75px);
    -ms-transform: translate(-50%, 75px);
    transform: translate(-50%, 75px);
    color: #8a9bad;
    font-size: 12px;
}

.sidebar-header {
    background: transparent;
    color: #adb5bd;
    padding: 1.125rem 1.5rem 0.375rem;
    font-size: 0.75rem;
    text-transform: none;
    margin-bottom: 20px;
}

.sidebar-header a img {
    max-width: 100%;
}

.avt {
    margin-bottom: 20px;
}

.avt figure {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    position: relative;
    margin: auto;
    background: #fff;
    text-align: center;
    overflow: hidden;
    margin-bottom: 10px;
}

.avt figure img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.avt p {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

#sidebar {
    width: 220px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    overflow-y: scroll;
    background: #5c5c5e;
    color: #fff;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

#sidebar .dropdown-toggle::after {
    display: none;
}

#sidebar [data-toggle='collapse'] .icon:after {
    display: none;
}

#sidebar.active {
    margin-left: -250px;
}

.sidebar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.sidebar-toggler .sidebar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: '';
    background: no-repeat center center;
    background-size: 100% 100%;
    background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    cursor: pointer;
}

.sidebar {
    position: relative;
    width: 100%;
    z-index: 99;
}

.sidebar .nav-link {
    padding: 0.75rem 0.9rem;
}

.sidebar .sidebar-content {
    position: relative;
    border-radius: 0.25rem;
    margin-bottom: 1.25rem;
}

.sidebar .category-title {
    position: relative;
    margin: 0;
    padding: 12px 20px;
    padding-right: 46px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar.sidebar-default .category-title {
    border-bottom-color: #dee2e6;
}

.sidebar.sidebar-default .category-title > span {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
}

.sidebar.sidebar-default .nav li > a {
    color: #333;
}

.sidebar.sidebar-default .nav li > a.active,
.sidebar.sidebar-default .nav li > a[aria-expanded='true'],
.sidebar.sidebar-default .nav li > a:hover,
.sidebar.sidebar-default .nav li > a:focus {
    background-color: #4f4f51;
}

.sidebar .nav {
    position: relative;
    margin: 0;
    padding: 0.5rem 0;
}

.sidebar .nav li {
    position: relative;
    list-style: none;
}

.sidebar .nav li > a {
    font-size: 0.875rem;
    -webkit-transition: background 0.15s linear, color 0.15s linear;
    transition: background 0.15s linear, color 0.15s linear;
}

.sidebar .nav li > a[data-toggle='collapse'] {
    padding-right: 2rem;
}

.sidebar .nav li > a[data-toggle='collapse']:after {
    position: absolute;
    top: 0.7rem;
    right: 10px;
    height: 1.5rem;
    line-height: 1.5rem;
    display: block;
    content: '\e904';
    font-family: 'icon';
    font-size: 0.8em;
    font-weight: normal;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: -webkit-transform 0.2s ease-in-out;
}

.sidebar .nav li > a[data-toggle='collapse'][aria-expanded='true']:after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sidebar .nav li > a > i {
    width: 26px;
    float: left;
    top: 0;
    font-size: 1.6rem;
    margin-right: 10px;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
}

.sidebar .nav li ul {
    padding: 0.45rem 0;
    background-color: #646466;
}

.sidebar .nav li ul > li a {
    display: block;
    padding: 0.35rem 0.25rem 0.35rem 3.25rem;
}

.sidebar .nav > li > a,
.sidebar .nav li ul > li a {
    font-weight: 500;
}

.sidebar .nav > li > a:hover,
.sidebar .nav li ul > li a:hover {
    color: #ff6704;
}

.btn-secondary {
    background-color: #56697e;
}

.btn-secondary:hover {
    background-color: #56697e;
    border-color: #56697e;
}
.btn-outline-secondary {
    background-color: unset;
    border-color: #56697e;
    color: #56697e;
}
.btn-outline-secondary:hover {
    background-color: unset;
    border-color: #56697e;
    color: #56697e;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: #56697e;
    background-color: white;
    border-color: #56697e;
}

.btn-secondary:focus,
.btn-secondary.focus {
    color: #fff;
    background-color: #49494b;
    border-color: #434344;
    box-shadow: none;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #8a9bad;
    text-decoration: none;
    background-color: white;
}

.dropdown-item {
    color: #56697e;
}

// .mCustomScrollbar {
//   -ms-touch-action: pinch-zoom;
//   touch-action: pinch-zoom;
// }

// .mCustomScrollbar.mCS_no_scrollbar,
// .mCustomScrollbar.mCS_touch_action {
//   -ms-touch-action: auto;
//   touch-action: auto;
// }

// .mCustomScrollBox {
//   position: relative;
//   overflow: hidden;
//   height: 100%;
//   max-width: 100%;
//   outline: 0;
//   direction: ltr;
// }

// .mCSB_container {
//   overflow: hidden;
//   width: auto;
//   height: auto;
// }

// .mCSB_inside > .mCSB_container {
//   margin-right: 30px;
// }

// .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
//   margin-right: 0;
// }

// .mCS-dir-rtl > .mCSB_inside > .mCSB_container {
//   margin-right: 0;
//   margin-left: 30px;
// }

// .mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
//   margin-left: 0;
// }

// .mCSB_scrollTools {
//   position: absolute;
//   width: 16px;
//   height: auto;
//   left: auto;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   opacity: .75;
//   filter: "alpha(opacity=75)";
//   -ms-filter: "alpha(opacity=75)";
// }

// .mCSB_outside + .mCSB_scrollTools {
//   right: -26px;
// }

// .mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
// .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
//   right: auto;
//   left: 0;
// }

// .mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
//   left: -26px;
// }

// .mCSB_scrollTools .mCSB_draggerContainer {
//   position: absolute;
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   height: auto;
// }

// .mCSB_scrollTools a + .mCSB_draggerContainer {
//   margin: 20px 0;
// }

// .mCSB_scrollTools .mCSB_draggerRail {
//   width: 2px;
//   height: 100%;
//   margin: 0 auto;
//   border-radius: 16px;
// }

// .mCSB_scrollTools .mCSB_dragger {
//   cursor: pointer;
//   width: 100%;
//   height: 30px;
//   z-index: 1;
// }

// .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   position: relative;
//   width: 4px;
//   height: 100%;
//   margin: 0 auto;
//   border-radius: 16px;
//   text-align: center;
// }

// .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
// .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
//   width: 12px;
// }

// .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
//   width: 8px;
// }

// .mCSB_scrollTools .mCSB_buttonDown,
// .mCSB_scrollTools .mCSB_buttonUp {
//   display: block;
//   position: absolute;
//   height: 20px;
//   width: 100%;
//   overflow: hidden;
//   margin: 0 auto;
//   cursor: pointer;
// }

// .mCSB_scrollTools .mCSB_buttonDown {
//   bottom: 0;
// }

// .mCSB_horizontal.mCSB_inside > .mCSB_container {
//   margin-right: 0;
//   margin-bottom: 30px;
// }

// .mCSB_horizontal.mCSB_outside > .mCSB_container {
//   min-height: 100%;
// }

// .mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
//   margin-bottom: 0;
// }

// .mCSB_scrollTools.mCSB_scrollTools_horizontal {
//   width: auto;
//   height: 16px;
//   top: auto;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }

// .mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
// .mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
//   bottom: -26px;
// }

// .mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
//   margin: 0 20px;
// }

// .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   width: 100%;
//   height: 2px;
//   margin: 7px 0;
// }

// .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
//   width: 30px;
//   height: 100%;
//   left: 0;
// }

// .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   width: 100%;
//   height: 4px;
//   margin: 6px auto;
// }

// .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
// .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
//   height: 12px;
//   margin: 2px auto;
// }

// .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
//   height: 8px;
//   margin: 4px 0;
// }

// .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
// .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
//   display: block;
//   position: absolute;
//   width: 20px;
//   height: 100%;
//   overflow: hidden;
//   margin: 0 auto;
//   cursor: pointer;
// }

// .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
//   left: 0;
// }

// .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
//   right: 0;
// }

// .mCSB_container_wrapper {
//   position: absolute;
//   height: auto;
//   width: auto;
//   overflow: hidden;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin-right: 30px;
//   margin-bottom: 30px;
// }

// .mCSB_container_wrapper > .mCSB_container {
//   padding-right: 30px;
//   padding-bottom: 30px;
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
// }

// .mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
//   bottom: 20px;
// }

// .mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
//   right: 20px;
// }

// .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
//   bottom: 0;
// }

// .mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal,
// .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
//   right: 0;
// }

// .mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
//   left: 20px;
// }

// .mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
//   left: 0;
// }

// .mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
//   margin-right: 0;
//   margin-left: 30px;
// }

// .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
//   padding-right: 0;
// }

// .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
//   padding-bottom: 0;
// }

// .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
//   margin-right: 0;
//   margin-left: 0;
// }

// .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
//   margin-bottom: 0;
// }

// .mCSB_scrollTools,
// .mCSB_scrollTools .mCSB_buttonDown,
// .mCSB_scrollTools .mCSB_buttonLeft,
// .mCSB_scrollTools .mCSB_buttonRight,
// .mCSB_scrollTools .mCSB_buttonUp,
// .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
//   transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
// }

// .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
// .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
// .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
// .mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar {
//   -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
//   transition: width .2s ease-out .2s, height .2s ease-out .2s, margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
// }

// .mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
// .mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
//   opacity: 0;
//   filter: "alpha(opacity=0)";
//   -ms-filter: "alpha(opacity=0)";
// }

// .mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
// .mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools,
// .mCustomScrollBox:hover > .mCSB_scrollTools,
// .mCustomScrollBox:hover ~ .mCSB_scrollTools,
// .mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
// .mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag {
//   opacity: 1;
//   filter: "alpha(opacity=100)";
//   -ms-filter: "alpha(opacity=100)";
// }

// .mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.4);
//   filter: "alpha(opacity=40)";
//   -ms-filter: "alpha(opacity=40)";
// }

// .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.75);
//   filter: "alpha(opacity=75)";
//   -ms-filter: "alpha(opacity=75)";
// }

// .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.85);
//   filter: "alpha(opacity=85)";
//   -ms-filter: "alpha(opacity=85)";
// }

// .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.9);
//   filter: "alpha(opacity=90)";
//   -ms-filter: "alpha(opacity=90)";
// }

// .mCSB_scrollTools .mCSB_buttonDown,
// .mCSB_scrollTools .mCSB_buttonLeft,
// .mCSB_scrollTools .mCSB_buttonRight,
// .mCSB_scrollTools .mCSB_buttonUp {
//   // background-image: url(mCSB_buttons.png);
//   background-repeat: no-repeat;
//   opacity: .4;
//   filter: "alpha(opacity=40)";
//   -ms-filter: "alpha(opacity=40)";
// }

// .mCSB_scrollTools .mCSB_buttonUp {
//   background-position: 0 0;
// }

// .mCSB_scrollTools .mCSB_buttonDown {
//   background-position: 0 -20px;
// }

// .mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: 0 -40px;
// }

// .mCSB_scrollTools .mCSB_buttonRight {
//   background-position: 0 -56px;
// }

// .mCSB_scrollTools .mCSB_buttonDown:hover,
// .mCSB_scrollTools .mCSB_buttonLeft:hover,
// .mCSB_scrollTools .mCSB_buttonRight:hover,
// .mCSB_scrollTools .mCSB_buttonUp:hover {
//   opacity: .75;
//   filter: "alpha(opacity=75)";
//   -ms-filter: "alpha(opacity=75)";
// }

// .mCSB_scrollTools .mCSB_buttonDown:active,
// .mCSB_scrollTools .mCSB_buttonLeft:active,
// .mCSB_scrollTools .mCSB_buttonRight:active,
// .mCSB_scrollTools .mCSB_buttonUp:active {
//   opacity: .9;
//   filter: "alpha(opacity=90)";
//   -ms-filter: "alpha(opacity=90)";
// }

// .mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.15);
// }

// .mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.75);
// }

// .mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: rgba(0, 0, 0, 0.85);
// }

// .mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -80px 0;
// }

// .mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -80px -20px;
// }

// .mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -80px -40px;
// }

// .mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -80px -56px;
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-light-2.mCSB_scrollTools .mCSB_draggerRail {
//   width: 4px;
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.1);
//   border-radius: 1px;
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   width: 4px;
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.75);
//   border-radius: 1px;
// }

// .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   width: 100%;
//   height: 4px;
//   margin: 6px auto;
// }

// .mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.85);
// }

// .mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.9);
// }

// .mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -32px 0;
// }

// .mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -32px -20px;
// }

// .mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -40px -40px;
// }

// .mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -40px -56px;
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.1);
//   border-radius: 1px;
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.75);
//   border-radius: 1px;
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.85);
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -112px 0;
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -112px -20px;
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -120px -40px;
// }

// .mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -120px -56px;
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail {
//   width: 4px;
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.1);
//   border-radius: 2px;
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   width: 6px;
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.75);
//   border-radius: 2px;
// }

// .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   width: 100%;
//   height: 4px;
//   margin: 6px 0;
// }

// .mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   width: 100%;
//   height: 6px;
//   margin: 5px auto;
// }

// .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.85);
// }

// .mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.9);
// }

// .mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -16px 0;
// }

// .mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -16px -20px;
// }

// .mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -20px -40px;
// }

// .mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -20px -56px;
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.1);
//   border-radius: 2px;
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.75);
//   border-radius: 2px;
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.85);
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -96px 0;
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -96px -20px;
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -100px -40px;
// }

// .mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -100px -56px;
// }

// .mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.1);
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   width: 2px;
// }

// .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   width: 100%;
// }

// .mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   width: 100%;
//   height: 2px;
//   margin: 7px auto;
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.15);
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.75);
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.85);
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -80px 0;
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -80px -20px;
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -80px -40px;
// }

// .mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -80px -56px;
// }

// .mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.15);
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger,
// .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
// .mCS-rounded.mCSB_scrollTools .mCSB_dragger {
//   height: 14px;
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   width: 14px;
//   margin: 0 1px;
// }

// .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
// .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
// .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
// .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger {
//   width: 14px;
// }

// .mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   height: 14px;
//   margin: 1px 0;
// }

// .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
// .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
// .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
//   width: 16px;
//   height: 16px;
//   margin: -1px 0;
// }

// .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
// .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
//   width: 4px;
// }

// .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
// .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
// .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
//   height: 16px;
//   width: 16px;
//   margin: 0 -1px;
// }

// .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
// .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
//   height: 4px;
//   margin: 6px 0;
// }

// .mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: 0 -72px;
// }

// .mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: 0 -92px;
// }

// .mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: 0 -112px;
// }

// .mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: 0 -128px;
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.75);
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.15);
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.85);
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -80px -72px;
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -80px -92px;
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -80px -112px;
// }

// .mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -80px -128px;
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail,
// .mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail {
//   width: 4px;
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   background-color: transparent;
//   background-position: center;
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail {
//   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==);
//   background-repeat: repeat-y;
//   opacity: .3;
//   filter: "alpha(opacity=30)";
//   -ms-filter: "alpha(opacity=30)";
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   height: 4px;
//   margin: 6px 0;
//   background-repeat: repeat-x;
// }

// .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -16px -72px;
// }

// .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -16px -92px;
// }

// .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -20px -112px;
// }

// .mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -20px -128px;
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
//   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII=);
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -96px -72px;
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -96px -92px;
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -100px -112px;
// }

// .mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -100px -128px;
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-repeat: repeat-y;
//   background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
//   background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
//   background-image: -webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
//   background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
// }

// .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   background-repeat: repeat-x;
//   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
//   background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
//   background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));
//   background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0, rgba(255, 255, 255, 0) 100%);
// }

// .mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger,
// .mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger {
//   height: 70px;
// }

// .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
// .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger {
//   width: 70px;
// }

// .mCS-3d-dark.mCSB_scrollTools,
// .mCS-3d.mCSB_scrollTools {
//   opacity: 1;
//   filter: "alpha(opacity=30)";
//   -ms-filter: "alpha(opacity=30)";
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
//   border-radius: 16px;
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-3d.mCSB_scrollTools .mCSB_draggerRail {
//   width: 8px;
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.2);
//   -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
//           box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2);
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
// .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #555;
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   width: 8px;
// }

// .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   width: 100%;
//   height: 8px;
//   margin: 4px 0;
//   -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
//           box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2);
// }

// .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   width: 100%;
//   height: 8px;
//   margin: 4px auto;
// }

// .mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -32px -72px;
// }

// .mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -32px -92px;
// }

// .mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -40px -112px;
// }

// .mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -40px -128px;
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.1);
//   -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
//           box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1);
// }

// .mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
//           box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1);
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -112px -72px;
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -112px -92px;
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -120px -112px;
// }

// .mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -120px -128px;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools,
// .mCS-3d-thick.mCSB_scrollTools {
//   opacity: 1;
//   filter: "alpha(opacity=30)";
//   -ms-filter: "alpha(opacity=30)";
// }

// .mCS-3d-thick-dark.mCSB_scrollTools,
// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer,
// .mCS-3d-thick.mCSB_scrollTools,
// .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
//   border-radius: 7px;
// }

// .mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical,
// .mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical {
//   right: 1px;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools_vertical,
// .mCS-3d-thick.mCSB_scrollTools_vertical {
//   -webkit-box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
//           box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
// }

// .mCS-3d-thick-dark.mCSB_scrollTools_horizontal,
// .mCS-3d-thick.mCSB_scrollTools_horizontal {
//   bottom: 1px;
//   -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
//           box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5);
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   border-radius: 5px;
//   -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
//           box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
//   width: 12px;
//   margin: 2px;
//   position: absolute;
//   height: auto;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
//           box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4);
//   height: 12px;
//   width: auto;
// }

// .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
// .mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #555;
// }

// .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.05);
//   -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
//           box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
// }

// .mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: transparent;
// }

// .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -32px -72px;
// }

// .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -32px -92px;
// }

// .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -40px -112px;
// }

// .mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -40px -128px;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools {
//   -webkit-box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
//           box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2);
// }

// .mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
//   -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
//           box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2);
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   -webkit-box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
//           box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2);
// }

// .mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
//           box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2);
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #777;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
//   background-color: #fff;
//   background-color: rgba(0, 0, 0, 0.05);
//   -webkit-box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
//           box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1);
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-minimal.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: transparent;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -112px -72px;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -112px -92px;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -120px -112px;
// }

// .mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -120px -128px;
// }

// .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
// .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
//   right: 0;
//   margin: 12px 0;
// }

// .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
// .mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
// .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
// .mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
//   bottom: 0;
//   margin: 0 12px;
// }

// .mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical,
// .mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical {
//   left: 0;
//   right: auto;
// }

// .mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger,
// .mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger {
//   height: 50px;
// }

// .mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
// .mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger {
//   width: 50px;
// }

// .mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.2);
//   filter: "alpha(opacity=20)";
//   -ms-filter: "alpha(opacity=20)";
// }

// .mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.5);
//   filter: "alpha(opacity=50)";
//   -ms-filter: "alpha(opacity=50)";
// }

// .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.2);
//   filter: "alpha(opacity=20)";
//   -ms-filter: "alpha(opacity=20)";
// }

// .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.5);
//   filter: "alpha(opacity=50)";
//   -ms-filter: "alpha(opacity=50)";
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-light-3.mCSB_scrollTools .mCSB_draggerRail {
//   width: 6px;
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.2);
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   width: 6px;
// }

// .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   width: 100%;
//   height: 6px;
//   margin: 5px 0;
// }

// .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
// .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
//   width: 12px;
// }

// .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
// .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
// .mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
//   height: 12px;
//   margin: 2px 0;
// }

// .mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -32px -72px;
// }

// .mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -32px -92px;
// }

// .mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -40px -112px;
// }

// .mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -40px -128px;
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.75);
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.85);
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.1);
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -112px -72px;
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -112px -92px;
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -120px -112px;
// }

// .mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -120px -128px;
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-inset.mCSB_scrollTools .mCSB_draggerRail {
//   width: 12px;
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.2);
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   width: 6px;
//   margin: 3px 5px;
//   position: absolute;
//   height: auto;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }

// .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
//   height: 6px;
//   margin: 5px 3px;
//   position: absolute;
//   width: auto;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }

// .mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
// .mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail {
//   width: 100%;
//   height: 12px;
//   margin: 2px 0;
// }

// .mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
// .mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp,
// .mCS-inset.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -32px -72px;
// }

// .mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
// .mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown,
// .mCS-inset.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -32px -92px;
// }

// .mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
// .mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft,
// .mCS-inset.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -40px -112px;
// }

// .mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
// .mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight,
// .mCS-inset.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -40px -128px;
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.75);
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.85);
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.1);
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp {
//   background-position: -112px -72px;
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown {
//   background-position: -112px -92px;
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft {
//   background-position: -120px -112px;
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight,
// .mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight {
//   background-position: -120px -128px;
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
// .mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: transparent;
//   border-width: 1px;
//   border-style: solid;
//   border-color: #fff;
//   border-color: rgba(255, 255, 255, 0.2);
//   -webkit-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   box-sizing: border-box;
// }

// .mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
//   border-color: #000;
//   border-color: rgba(0, 0, 0, 0.2);
// }

// .mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.6);
// }

// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.6);
// }

// .mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.75);
// }

// .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.85);
// }

// .mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #000;
//   background-color: rgba(0, 0, 0, 0.9);
// }

// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.75);
// }

// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.85);
// }

// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
// .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar {
//   background-color: #fff;
//   background-color: rgba(255, 255, 255, 0.9);
// }

.datepicker {
    padding: 4px;
    border-radius: 4px;
    direction: ltr;
    /*.dow {
		border-top: 1px solid #ddd !important;
	}*/
}

.datepicker-inline {
    width: 220px;
}

.datepicker.datepicker-rtl {
    direction: rtl;
}

.datepicker.datepicker-rtl table tr td span {
    float: right;
}

.datepicker-dropdown {
    top: 0;
    left: 0;
}

.datepicker-dropdown:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
}

.datepicker-dropdown:after {
    content: '';
    display: inline-block;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-top: 0;
    position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
    left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
    left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
    right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
    right: 7px;
}

.datepicker-dropdown.datepicker-orient-top:before {
    top: -7px;
}

.datepicker-dropdown.datepicker-orient-top:after {
    top: -6px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
    bottom: -7px;
    border-bottom: 0;
    border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
    bottom: -6px;
    border-bottom: 0;
    border-top: 6px solid #ffffff;
}

.datepicker > div {
    display: none;
}

.datepicker.days div.datepicker-days {
    display: block;
}

.datepicker.months div.datepicker-months {
    display: block;
}

.datepicker.years div.datepicker-years {
    display: block;
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.datepicker td,
.datepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
    background-color: transparent;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
    background: #eeeeee;
    cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
    color: #999999;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    background: none;
    color: #999999;
    cursor: default;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: #fde19a;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
    background-image: -webkit-linear-gradient(top, #fdd49a, #fdf59a);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#fdd49a), to(#fdf59a));
    background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
    border-color: #fdf59a #fdf59a #fbed50;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #000;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
    background-color: #fdf59a;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
    background-color: #fbf069 \9;
}

.datepicker table tr td.today:hover:hover {
    color: #000;
}

.datepicker table tr td.today.active:hover {
    color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
    background: #eeeeee;
    border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
    background-color: #f3d17a;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
    background-image: -webkit-linear-gradient(top, #f3c17a, #f3e97a);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#f3c17a), to(#f3e97a));
    background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
    border-color: #f3e97a #f3e97a #edde34;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    border-radius: 0;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
    background-color: #f3e97a;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
    background-color: #efe24b \9;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
    background-color: #9e9e9e;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
    background-image: -webkit-linear-gradient(top, #b3b3b3, #808080);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#b3b3b3), to(#808080));
    background-image: linear-gradient(to bottom, #b3b3b3, #808080);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
    border-color: #808080 #808080 #595959;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
    background-color: #808080;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
    background-color: #666666 \9;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    background-color: #006dcc;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0088cc), to(#0044cc));
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
    background-color: #0044cc;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
    background-color: #003399 \9;
}

.datepicker table tr td span {
    display: block;
    width: 23%;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
    border-radius: 4px;
}

.datepicker table tr td span:hover {
    background: #eeeeee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
    background: none;
    color: #999999;
    cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
    background-color: #006dcc;
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0044cc));
    background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#0088cc), to(#0044cc));
    background-image: linear-gradient(to bottom, #0088cc, #0044cc);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088cc', endColorstr='#0044cc', GradientType=0);
    border-color: #0044cc #0044cc #002a80;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #0044cc;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
    background-color: #003399 \9;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
    color: #999999;
}

.datepicker th.datepicker-switch {
    width: 145px;
}

.datepicker thead tr:first-child th,
.datepicker tfoot tr th {
    cursor: pointer;
}

.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
    background: #eeeeee;
}

.datepicker .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
}

.datepicker thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.input-daterange input {
    text-align: center;
}

.input-daterange input:first-child {
    border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
    border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
    display: inline-block;
    width: auto;
    min-width: 16px;
    height: 20px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: 20px;
    text-align: center;
    text-shadow: 0 1px 0 #ffffff;
    vertical-align: middle;
    background-color: #eeeeee;
    border: 1px solid #ccc;
    margin-left: -5px;
    margin-right: -5px;
}

.datepicker.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    display: none;
    min-width: 160px;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    color: #333333;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 20px;
}

.datepicker.dropdown-menu th,
.datepicker.dropdown-menu td {
    padding: 4px 5px;
}

.card-search .card-title {
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.card-search .custom-select,
.card-search .form-control {
    font-size: 14px;
}

.card-list {
    margin-bottom: 2rem;
    font-size: 14px;
}

.card-list h3 {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.4;
}

.card-list p {
    margin-bottom: 0;
}

.card-list .form-control {
    font-size: 12px;
}

.card-list label {
    margin-bottom: 0.24rem;
}

.card-list .card-body {
    padding: 15px 35px 75px 0;
    margin-left: -10px;
    position: relative;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    vertical-align: baseline;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.card-list .card-body:after {
    content: '';
    background-color: #ccc;
    width: 1px;
    height: -webkit-calc(100% - 30px);
    height: calc(100% - 30px);
    position: absolute;
    top: 15px;
    right: 0;
}

@media (max-width: 1270px) {
    .card-list .card-body {
        padding: 15px 35px 15px 0;
    }
}

.card-list-head {
    margin-bottom: 10px;
}

.card-list-img figure {
    display: block;
    margin: 0;
    width: 100%;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    padding-top: 100%;
}

.card-list-img figure img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    object-fit: contain;
    background-color: #838896;
}

.card-list-img .add-img .upload-img div {
    margin-bottom: 0;
    padding-top: 100%;
    background: url(~src/assets/img/img-default.png) no-repeat center 45%;
}

.card-list-img .add-img .upload-img div b {
    top: -webkit-calc(100% - 39%);
    top: calc(100% - 39%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.card-list-img .add-img .upload-img div p {
    top: 44%;
}

.card-list-img.card-list-imgEdit .upload-img div {
    padding-top: 0;
}

.card-list-img.card-list-imgEdit .upload-img div b {
    top: -webkit-calc(100% - 65px);
    top: calc(100% - 65px);
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.card-list-desc {
    padding: 0;
    margin-bottom: 15px;
}

.card-list-desc .form-group {
    margin-bottom: 2px;
}

.card-list-desc .input-group-prepend {
    width: 22px;
    position: relative;
    top: 5px;
}

.card-list-prize small {
    vertical-align: top;
}

.card-list-prize strong {
    font-size: 30px;
    font-weight: 500;
}

.card-list-prize .btn {
    max-width: 100%;
    float: right;
    position: relative;
}

.card-list-text-box {
    background-color: #eceef3;
    padding: 6px 10px 8px 35px;
    line-height: 1.2;
    min-height: 0px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 15px;
    font-size: 12px;
}

@media (max-width: 1270px) {
    .card-list-text-box {
        position: relative;
        width: 100%;
        bottom: inherit;
    }
}

.card-list-text-box:after {
    font-family: 'icon';
    content: '\e928';
    font-size: 1.2rem;
    position: absolute;
    left: 8px;
    top: 5px;
    z-index: 1;
    color: #56697e;
}

.card-list-load {
    height: -webkit-calc(100% - 30px);
    height: calc(100% - 30px);
    position: relative;
    margin: 15px 0;
}

.card-list-load .text {
    position: absolute;
    top: 0;
    right: 20px;
    line-height: 1.2;
}

.card-list-load .text span,
.card-list-load .text strong {
    display: block;
}

.card-list-load .text span {
    font-size: 12px;
}

.card-list-load .text strong {
    font-size: 20px;
    font-weight: 600;
}

.card-list-load .download {
    text-align: center;
    width: 100%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.card-list-load .download > *,
.card-list-load .download a > *,
.card-list-load .download label img {
    display: block;
}

.card-list-load .download > a {
    display: inline-block;
    margin: 0 10px;
}

.card-list-load .download input {
    display: none;
    height: 0;
}

.card-list-load .download img {
    margin: 15px auto 10px;
    width: 30.86px;
    height: 36px;
}

.card-list-load small {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    text-align: right;
    padding-right: 20px;
}

.card-list-load-relate .download {
    position: relative;
    top: inherit;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
    padding-top: 85px;
}

.card-list-add label .icon-moon {
    color: #e12d2d;
    font-size: 9px;
    position: relative;
    top: -2px;
}

.card-list-add .card-body {
    padding: 15px 35px 15px 0;
}

.card-list-add .card-list-load .icon-moon {
    color: #e12d2d;
}

.card-list-add .card-list-desc .input-group span {
    padding: 5px 6px 0;
}

.card-list-add .card-list-desc .form-control {
    border-radius: 4px !important;
}

.card-list-add .card-list-desc .form-group {
    margin-bottom: inherit;
}

.card-list-add .card-list-desc .input-group-prepend {
    top: 8px;
}

.card-list-add .card-list-text-box {
    position: relative;
    width: 100%;
    bottom: inherit;
}

.card-list-add .card-list-text-box textarea.form-control {
    height: 68px;
    max-height: 68px;
}

.card-add-detail {
    margin-bottom: 2rem;
}

.card-add-detail .card-title {
    font-size: 20px;
    font-weight: 600;
    margin: 5px 0 15px;
    display: inline-block;
}

.card-add-detail .card-title i {
    font-size: 1.1em;
    position: relative;
    top: 2px;
    margin-right: 10px;
}

.card-add-detail .card-detail {
    position: relative;
    margin-bottom: 1rem;
    color: #56697e;
}

.card-add-detail .card-detail .btn-bin {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
}

.card-add-detail .card-detail img {
    max-width: 100%;
    width: 100%;
}

.card-add-detail .card-detail-view {
    border: none;
}

.card-add-detail .card-detail-view strong {
    color: #354251;
    font-weight: 600;
}

.card-add-detail .card-detail-view strong,
.card-add-detail .card-detail-view p {
    margin-bottom: 0.25rem;
}

.card-add-detail .card-detail-view ol,
.card-add-detail .card-detail-view ul {
    padding-left: 20px;
}

.card-wrap-btn {
    position: relative;
    margin-bottom: 20px;
}

.card-wrap-btn:after {
    content: '';
    background-color: #56697e;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
}

.card-wrap-btn .btn {
    position: relative;
    z-index: 2;
}

.card-wrap-btn .btn i {
    font-size: 0.8em;
    font-style: normal;
    margin-left: 10px;
}

[data-toggle='collapse'] .icon:after {
    content: '\e90b';
    font-family: 'icon';
}

[data-toggle='collapse'].collapsed .icon:after {
    content: '\e90a';
    font-family: 'icon';
}

.card-hide div.collapse[aria-expanded='false'] {
    display: block;
    overflow: hidden;
}

.card-hide div.collapsing[aria-expanded='false'] {
    height: 300px;
}

.card-hide div.collapse[aria-expanded='false'] {
    height: 300px;
}

.card-hide div.collapse-tall[aria-expanded='false'] {
    height: 920px;
}

.card-hide div.collapse.show {
    height: auto;
}

// .card-hide a.collapsed:before {
//   content: 'แสดงทั้งหมด';
// }

.card-hide a:not(.collapsed):before {
    content: 'ซ่อนข้อมูล';
}

.card-add-hide div.collapse[aria-expanded='false'] {
    display: block;
    overflow: hidden;
}

.card-add-hide div.collapsing[aria-expanded='false'] {
    height: 240px;
}

.card-add-hide div.collapse[aria-expanded='false'] {
    height: 240px;
}

.card-add-hide div.collapse.show {
    height: auto;
}

.card-add-hide a.collapsed:before {
    content: 'เพิ่มข้อมูลไฟลท์ ช่วงเวลาเดินทาง และไฮไลท์';
}

.card-add-hide a:not(.collapsed):before {
    content: 'เพิ่มข้อมูลไฟลท์ ช่วงเวลาเดินทาง และไฮไลท์';
}

.card-add-hide i {
    font-size: 0.8em;
    font-style: normal;
    margin-left: 10px;
}

.card-add-hide a[data-toggle='collapse'] {
    text-decoration: underline;
}

.card-add-hide [data-toggle='collapse'][aria-expanded='true'] {
    color: #354251;
    background: none;
}

.calendar .input-group.date {
    background: #fff;
    border: solid 1px #ccc;
    border-radius: 4px;
    position: relative;
}

.calendar .input-group.date .form-control {
    background: transparent;
    border: none;
    width: 100%;
    padding-right: 23px;
}

.calendar .input-group.date .input-group-addon {
    position: absolute;
    right: 0;
    height: 100%;
}

.my-submit-btn {
    width: 150px;
    height: 35px;
    border-radius: 4px;
    background-color: #ff6704;
    color: white;
}

.my-cancel-btn {
    width: 150px;
    height: 35px;
    border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #56697e;
    color: #56697e;
    background-color: white;
}

.container-medium-width {
    margin-left: 5px;
    max-width: 980px;
}

.isFormSubmitted .ng-invalid {
    border-radius: 4px;
    border: solid 1px red !important;
}

.modal .modal-sm .modal-content {
    width: 560px;
    left: -96px;
}

.whitespace-prewrap {
    white-space: pre-wrap;
}
