// _variables.scss

$color-white: #fff;
$color-red: #ff0000;
$color-dark-light: #8a9bad;
$color-orange-text-note: #ff6704;
$color-dark-grey: #5c5c5e;

$color-grey-text-normal: #56697e;
$color-orange: #ffad00;
$color-blue-tourwow: #146d99;
$color-link: #2b66b1;
