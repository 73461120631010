@import 'variables.scss';

.text- {
    &dark-grey {
        color: $color-dark-grey
    }
    &grey-text-normal {
        color: $color-grey-text-normal
    }
    &red {
        color: $color-red
    }
    &orange {
        color: $color-orange
    }
    &blue-tourwow {
        color: $color-blue-tourwow;
    }
    &dark-light {
        color: $color-dark-light;
    }
}

.text-xxl {
    font-size: 20px;
}

.text-xl {
    font-size: 18px;
}

.text-lg {
    font-size: 16px;
}

.text-md {
    font-size: 14px;
}

.text-sm {
    font-size: 12px;
}

.text-xs {
    font-size: 10px;
}

.text-xxs {
    font-size: 8px;
}