// _override.scss Override class bootstrap 4
@import 'variables.scss';

// Button
.btn-primary {
    // background-color: $color-dark-grey;
    border: none;

    &:hover {
        background-color: $color-orange-text-note;
    }
}

.form-control {
    &:focus {
        border-color: #d5d5d5;
        box-shadow: none !important;
    }
}

// .select2-container{
//   width: 100% !important;
// }
.search-data .col-form-label {
    width: 6rem;
}

.card-list-load .action {
    top: 0;
    color: $color-dark-light;
    text-decoration: underline;

    a {
        cursor: pointer;
        margin-right: 20px;
        font-size: 14px;

        &:hover {
            color: $color-dark-grey;
        }
    }
}

.card-list-load .text {
    top: 10%;
}

.card-hide div.collapse-tall[aria-expanded='false'] {
    height: auto;
}

.card-hide div.collapse[aria-expanded='false'] {
    height: auto;
}

.table_periods {
    table {
        width: 100%;

        tr {
            th {
                text-align: center;
                border: solid 1px #bec9d5;

                &:first-child {
                    width: 50%;
                }
            }

            td {
                padding: 15px 10px;
                text-align: center;
                border: solid 1px #bec9d5;

                strong {
                    font-size: 18px;
                }
            }
        }
    }
}

.btn-orange {
    border-radius: 4px;
    background: #ff6704;
    color: #fff;
    //width: 25%;
}

.btn-default {
    border: 1px solid #56697e;
}

.modals {
    img {
        width: 80px;
        height: 80px;
        border-radius: 10px;
    }
}

.modal-header {
    padding: 0 1rem;
    border-bottom: none;
}

.modal-dialog {
    max-width: 365px !important;
    width: 365px !important;
}

.modal-body {
    .header {
        strong {
            display: block;
            font-size: 16px;
            line-height: 18px;
            color: #354251;
        }

        span {
            display: block;
            font-size: 12px;
            color: #56697e;
        }

        .bg-icons_modal {
            width: 60px;
            height: 60px;
            background-image: url('~src/assets/img/icon/icon-complete.svg');
            background-repeat: no-repeat;
            background-size: 60px;
            margin: 15px auto;
            margin-bottom: 25px;
        }
    }

    .detail {
        padding: 16px 24px;

        span {
            font-size: 12px;
            color: #56697e;
        }

        strong {
            font-size: 18px;
        }

        p {
            font-size: 12px;
            color: #56697e;
        }
    }

    .footer {
        button {
            margin-right: 10px;
        }
    }
}

.btn-medium {
    width: 85px;
    height: 35px;
}

.btn-large {
    width: 150px;
    height: 35px;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-checkbox {
    display: inline-flex;
    position: relative;
    width: 18px;
    height: 20px;
    z-index: 2;

    .custom-control-input {
        z-index: 1;
        cursor: pointer;

        & ~ .custom-control-label {
            &::before {
                left: 0;
            }

            &::after {
                left: 0;
            }
        }

        &:checked ~ .custom-control-label {
            &::before {
                left: 0;
                background-color: #ff6704;
            }

            &::after {
                left: 0;
            }
        }
    }
}

.form-group {
    label {
        font-weight: bold;
    }

    input,
    textarea,
    select {
        font-size: 12px;
    }
}

.bs-datepicker-head {
    background-color: #eeeeee !important;

    span {
        color: #54708b !important;
    }
}

// temporary fix for loader's overlay not being properly closed when used with ckeditor's image upload functionality
.ngx-overlay.foreground-closing {
    display: none !important;
}

.slick-slider {
    margin: 0;
    .slick-arrow {
        z-index: 99;
        &::before {
            color: white;
            opacity: 1;
        }
    }
    .slick-prev {
        left: 5px;
    }

    .slick-next {
        right: 5px;
    }

    .slick-track {
        min-width: 548px !important;
        display: flex !important;
        justify-content: center !important;
    }

    .slick-slide {
        width: 116px !important;
        margin-right: 8px;
    }
}
