$color-headline: #344251;
$color-paragraph: #465668;

@import url('https://fonts.googleapis.com/css2?family=Krub&family=Prompt&display=swap');
.ck.ck-heading_heading2 > .ck-button__label {
    font-family: 'Prompt', sans-serif;
    font-size: 30px !important;
    font-weight: bold !important;
    color: $color-headline;
}
.ck.ck-heading_heading3 > .ck-button__label {
    font-family: 'Prompt', sans-serif;
    font-size: 26px !important;
    font-weight: bold !important;
    color: $color-headline;
}
.ck.ck-heading_heading4 > .ck-button__label {
    font-family: 'Prompt', sans-serif;
    font-size: 22px !important;
    font-weight: bold !important;
    color: $color-headline;
}
.ck.ck-heading_heading5 > .ck-button__label {
    font-family: 'Prompt', sans-serif;
    font-size: 20px !important;
    font-weight: bold !important;
    color: $color-headline;
}
.ck.ck-heading_heading6 > .ck-button__label {
    font-family: 'Prompt', sans-serif;
    font-size: 19px !important;
    font-weight: bold !important;
    color: $color-headline;
}
.ck.ck-heading_paragraph > .ck-button__label {
    font-family: 'Krub', sans-serif;
    font-size: 18px !important;
    color: $color-paragraph;
}

.ck-content,
.editor-view {
    h2 {
        font-family: 'Prompt', sans-serif;
        font-size: 30px;
        font-weight: bold;
        color: $color-headline;
    }
    h3 {
        font-family: 'Prompt', sans-serif;
        font-size: 26px;
        font-weight: bold;
        color: $color-headline;
    }
    h4 {
        font-family: 'Prompt', sans-serif;
        font-size: 22px;
        font-weight: bold;
        color: $color-headline;
    }
    h5 {
        font-family: 'Prompt', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: $color-headline;
    }
    h6 {
        font-family: 'Prompt', sans-serif;
        font-size: 19px;
        font-weight: bold;
        color: $color-headline;
    }

    p,
    li {
        font-family: 'Krub', sans-serif;
        font-size: 18px;
        color: $color-paragraph;
    }
}
